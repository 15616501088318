import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import config from '../../logic/config';
import { GetLeaseOrderList } from '../../logic/network';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import PageTitle from '../PageTitle';
import Alert from '@material-ui/lab/Alert';
import { FormatDate } from '../../logic/utils';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

export default function OrdersPage() {
    let history = useHistory();
    const location = useLocation();
    const [ordersList, setOrdersList] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const classes = useStyles();
    const { currentUser } = useAuth();

    // useEffect( () => {
    //     if (!currentUser) {
    //          history.push("/login");
    //     }

    //     setLoading(true)
    //     const fetchOrdersList = async () => {
    //         try {
    //             const response = await GetLeaseOrderList(1)
    //             if (response.data?.leaseorder) {
    //                 setOrdersList(response.data.leaseorder)
    //                 config.user.setOrdersCount(response.data.leaseorder.length)
    //             }
    //             setLoading(false)
    //             if (response.data?.code !== 1) {
    //                 setError("Failed to load orders list. Please try again!")
    //             }
    //         } catch (err) {
    //             setError("Failed to load orders list. Please try again!")
    //             setLoading(false)
    //             console.log(err)
    //         }

    //     }
    //     fetchOrdersList();

    // }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push('/');
    };

    const HandleOpenOderInfo = orderId => {
        history.push({
            pathname: '/order/info',
            state: {
                orderId: orderId,
            },
        });
    };

    return (
        <React.Fragment>
            {loading && (
                <LinearProgress className={classes.fullWidth} color="primary" />
            )}
            {error && (
                <Alert
                    variant="outlined"
                    className={classes.fullWidth}
                    severity="error"
                    onClose={() => {
                        setError(null);
                    }}>
                    {error}
                </Alert>
            )}
            <PageTitle title={'Orders'} backButtonCallback={HandleBack} />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                {!loading && ordersList.length === 0 && (
                    <h4>Orders not found</h4>
                )}
                <Grid item style={{ width: '100%' }}>
                    <List dense={true}>
                        {ordersList.map((order, index) => {
                            return (
                                <React.Fragment key={'ListItem' + index}>
                                    <ListItem
                                        component={Paper}
                                        style={{
                                            marginBottom: '10px',
                                            height: '4rem',
                                        }}
                                        onClick={() => {
                                            HandleOpenOderInfo(order.id);
                                        }}>
                                        <ListItemText>
                                            <strong>{order.name}</strong>
                                        </ListItemText>
                                        <ListItemText
                                            style={{ textAlign: 'center' }}
                                            className={classes.primaryColor}>
                                            <strong>${order.amount}</strong>
                                        </ListItemText>
                                        <ListItemText
                                            style={{ textAlign: 'right' }}
                                            primary={FormatDate(order.date)}
                                        />
                                        <ListItemText
                                            style={{ textAlign: 'right' }}
                                            className={classes.primaryColor}>
                                            <ArrowForwardIosIcon
                                                style={{ fontSize: 15 }}
                                            />
                                        </ListItemText>
                                    </ListItem>
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
