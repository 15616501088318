/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useContext, useState, useEffect } from 'react';
import app, { auth } from '../firebase';
import firebase from 'firebase/app';
import config from '../logic/config';
import {
    User,
    RecaptchaVerifier,
} from '../../node_modules/@firebase/auth-types';
import { UserInfo } from '../interfaces';

declare global {
    interface Window {
        recaptchaVerifier: RecaptchaVerifier | null;
        confirmationResult: any;
    }
}

enum ProfileTypes {
    ProfileTypeIndividual = 'Individual',
    ProfileTypeBusiness = 'Business',
}

interface IAuthContext {
    currentUser: User | null;
    getProfileType: () => ProfileTypes;
    setTokenInfo: (token: string) => void;
}

const AuthContext = React.createContext<IAuthContext>({
    currentUser: null,
    getProfileType: () => '' as ProfileTypes,
    setTokenInfo: () => {},
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [token, setToken] = useState<string>('');
    const [userInfo, setUserInfo] = useState<UserInfo>();

    const [loading, setLoading] = useState(true);

    async function signup(email: string, password: string) {
        return await auth.createUserWithEmailAndPassword(email, password);
    }

    async function login(phoneNumber: string) {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                'firebase-recapcha',
                {
                    size: 'invisible',
                    callback: () => {
                        console.log('[recaptchaVerifier] callback');
                    },
                    'error-callback': function () {
                        console.log('[recaptchaVerifier] error-callback;');
                        window.recaptchaVerifier?.clear();
                    },
                },
                app,
            );
        }

        return await auth.signInWithPhoneNumber(
            phoneNumber,
            window.recaptchaVerifier,
        );
    }

    function updateUserInfo(userInfo: UserInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        config.user.userInfo = userInfo;
        setUserInfo(userInfo);
    }

    function setTokenInfo(token: string) {
        setToken(token);
    }

    function getUserInfo() {
        let localStorageUserInfo: UserInfo | null;
        localStorageUserInfo = JSON.parse(
            localStorage.getItem('userInfo') ?? '',
        );
        config.user.userInfo = localStorageUserInfo;

        return localStorageUserInfo;
    }

    function cleanupRecapcha() {
        window.recaptchaVerifier = null;
        window.confirmationResult = null;
    }

    async function logout() {
        setCurrentUser(null);
        cleanupRecapcha();
        return await auth.signOut();
    }

    async function resetPassword(email: string) {
        return await auth.sendPasswordResetEmail(email);
    }

    async function updateEmail(email: string) {
        return currentUser !== null && (await currentUser.updateEmail(email));
    }

    async function updatePassword(password: string) {
        return (
            currentUser !== null && (await currentUser.updatePassword(password))
        );
    }

    const getProfileType = () => {
        return config.user.isBusinessProfile()
            ? ProfileTypes.ProfileTypeBusiness
            : ProfileTypes.ProfileTypeIndividual;
    };

    useEffect(() => {
        const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(user => {
            if (user !== null) {
                console.log('[auth] onAuthStateChanged');
                setCurrentUser(user);
                // user.getIdToken().then(idToken => {
                //     let userInfo = getUserInfo();
                //     if (userInfo) {
                //         userInfo.firebaseToken = idToken;
                //     } else {
                //         userInfo = { firebaseToken: idToken };
                //     }

                //     updateUserInfo(userInfo);
                // });
            }
            setLoading(false);
        });

        const unsubscribeOnIdTokenChanged = firebase
            .auth()
            .onIdTokenChanged(function (user) {
                if (user !== null) {
                    console.log('[auth] onIdTokenChanged');
                    setCurrentUser(user);
                    // user.getIdToken().then(idToken => {
                    //     let userInfo = getUserInfo();
                    //     if (userInfo) {
                    //         userInfo.firebaseToken = idToken;
                    //     } else {
                    //         userInfo = { firebaseToken: idToken };
                    //     }

                    //     updateUserInfo(userInfo);
                    // });
                }
            });
    }, []);

    const value = {
        token,
        currentUser,
        userInfo,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateUserInfo,
        getUserInfo,
        getProfileType,
        cleanupRecapcha,
        setTokenInfo,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
