import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import { FirebaseMessaging } from '@firebase/messaging-types';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export let messaging: FirebaseMessaging | null = null;
export let onMessageListener: () => Promise<any>;

try {
    messaging = firebase.messaging();

    onMessageListener = onMessageListener = async () =>
        await new Promise<any>(resolve => {
            messaging?.onMessage(payload => {
                console.log('[firebase.js] onMessageListener ', payload);
                resolve(payload);
            });
        });
} catch (err) {
    console.error(err);
}

export const auth = app.auth();
export default app;
