import React from 'react';
import { Button, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const PageTitle: React.FC<{
    title: string;
    backButtonCallback: () => void;
}> = ({ title, backButtonCallback }) => {
    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
            <Grid item xs={2}>
                <Button onClick={backButtonCallback}>
                    <ArrowBackIosIcon /> Back
                </Button>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
                <h2 style={{ marginTop: 0, marginBottom: 0 }}>{title}</h2>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    );
};
export default PageTitle;
