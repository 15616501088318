import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Link, Paper } from '@material-ui/core';
import PageTitle from '../PageTitle';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

export default function HelpPage() {
    let history = useHistory();
    const classes = useStyles();

    const HandleBack = () => {
        history.push('/');
    };

    return (
        <React.Fragment>
            <PageTitle title={'Help'} backButtonCallback={HandleBack} />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                <Grid item style={{ width: '100%' }}>
                    <List dense={true}>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Web</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <Link
                                    href={'https://charge.us/pbsupport'}
                                    target="_blank">
                                    https://charge.us/pbsupport
                                </Link>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Email</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <Link href={'mailto:pbsupport@charge.us'}>
                                    pbsupport@charge.us
                                </Link>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>FAQ</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <Link
                                    href={'https://charge.us/pbfaq'}
                                    target="_blank">
                                    https://charge.us/pbfaq
                                </Link>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Phone</strong>
                            </ListItemText>
                            <ListItemText style={{ textAlign: 'right' }}>
                                <Link href={'tel:+16786649115'}>
                                    {' '}
                                    +1(678)664-9115
                                </Link>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
