import React, { useEffect, useState } from 'react';
import { Button, Link, Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { getEvChargingStationById } from '../../../../logic/network';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import PageTitle from '../../../PageTitle';
import config from '../../../../logic/config';
// import {useAuth} from "../../../../contexts/AuthContext";
import PropTypes from 'prop-types';

export default function EvChargingStationConfirmPagePartial(props) {
    let history = useHistory();
    let location = useLocation();
    let { locationId } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // eslint-disable-next-line
    const [isValidLocationId, setIsValidLocationId] = useState(true);
    const [locationObject, setLocationObject] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    // const {getUserInfo, updateUserInfo } = useAuth()
    // const [merchantName, setMerchantName] = React.useState(null)

    useEffect(() => {
        const asyncLoadFunction = async () => {
            setLoading(true);
            try {
                // setIsValidLocationId(true)
                // if (location.state?.location) {
                //     setLocationObject(location.state.location)
                // }
                const locationResp = await getEvChargingStationById(locationId);
                if (locationResp.data?.location) {
                    setLocationObject(locationResp.data.location);
                }
            } catch (err) {
                console.error(err);
                setError('Something wrong with location');
                setLoading(false);
                return;
            }
            setLoading(false);
        };
        asyncLoadFunction();
    }, [history, location, locationId]);

    const HandleBack = () => {
        history.push('/scan');
        // if (location.state?.from) {
        //     history.push({
        //         pathname: location.state.from,
        //         state: {
        //             location: new Location(location.state.location, LOCATION_TYPE_CHARGING_STATION, location.state.location.coordinates.lat, location.state.location.coordinates.lng)
        //         }
        //     });
        // } else {
        //     history.push("/scan");
        // }
    };

    const HandleConfirm = async () => {
        setLoading(true);
        setError(null);
        try {
            // Redirect to add card
            if (
                config.user.getCardsCount() === 0 &&
                !config.user.isBusinessProfile()
            ) {
                history.push({
                    pathname: '/cards/add',
                    state: {
                        from: location.pathname,
                    },
                });
                setLoading(false);
                return;
            }
            setSuccessMessage('Reservation success');
        } catch (err) {
            setLoading(false);
            setError('Invalid QR code. Please try to scan again!');
            return;
        }
        //TODO: Confirm station reservation
        setLoading(false);
    };

    return (
        <React.Fragment>
            {loading && <LinearProgress color="primary" />}
            {error && (
                <Alert
                    variant="outlined"
                    severity="error"
                    onClose={() => {
                        setError(null);
                    }}>
                    {error}
                </Alert>
            )}
            <PageTitle title={'Confirm'} backButtonCallback={HandleBack} />
            {successMessage && (
                <React.Fragment>
                    <Alert variant="outlined" severity="success">
                        {successMessage}
                    </Alert>
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <Button
                            style={{ width: '100%' }}
                            variant="contained"
                            onClick={() => history.push('/')}
                            color={'primary'}>
                            Ok
                        </Button>
                    </Grid>
                </React.Fragment>
            )}
            {isValidLocationId && locationObject && !successMessage && (
                <React.Fragment>
                    <Grid
                        container
                        direction="row"
                        wrap={'wrap'}
                        alignItems="center"
                        justify="center">
                        <Grid item>Please confirm info</Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                <TableRow key="boxId">
                                    <TableCell align="right">
                                        <strong>EV Charging Station:</strong>
                                    </TableCell>
                                    <TableCell align="left">
                                        {locationId}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'locationAddress'}>
                                    <TableCell align="right">
                                        <strong>Address:</strong>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Link
                                            target={'_blank'}
                                            href={`https://www.google.com/maps/search/?api=1&query=${locationObject.coordinates.lat},${locationObject.coordinates.lng}`}>
                                            {locationObject.address}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid
                        container
                        direction="row"
                        wrap={'wrap'}
                        alignItems="center"
                        justify="center"
                        style={{ paddingTop: '1rem' }}>
                        <Grid item>
                            <Button
                                disabled={loading}
                                onClick={HandleConfirm}
                                variant="contained"
                                color={'primary'}>
                                Rent confirm
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

EvChargingStationConfirmPagePartial.propTypes = {
    locationId: PropTypes.string.isRequired,
};
