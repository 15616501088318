import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';
import PageTitle from '../PageTitle';
import { Button, Grid } from '@material-ui/core';

const TableCell = withStyles({
    root: {
        borderBottom: 'none',
    },
})(MuiTableCell);

export default function CardsPage() {
    let history = useHistory();
    const [cards, setCards] = useState([]);
    const [primaryCard, setPrimaryCard] = useState([]);
    const { currentUser, getUserInfo } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            history.push('/login');
        }

        let userInfo = getUserInfo();
        if (userInfo) {
            setCards(userInfo.cards);
            setPrimaryCard(userInfo.primaryCard);
        }
    }, [currentUser, getUserInfo, history]);

    const HandleBack = () => {
        history.push('/');
    };

    const HandleAddCard = () => {
        history.push('/cards/add');
    };

    const HandleViewInfo = cardId => {
        history.push(`/cards/${cardId}`);
    };

    return (
        <React.Fragment>
            <PageTitle title={'Cards'} backButtonCallback={HandleBack} />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="center">
                <Grid
                    item
                    style={{
                        width: '100%',
                        marginBottom: '1rem',
                        textAlign: 'center',
                    }}>
                    <Button
                        style={{ width: '100%' }}
                        onClick={HandleAddCard}
                        variant="contained"
                        color={'primary'}>
                        Add card
                    </Button>
                </Grid>
                <Grid item>
                    {cards.map((card, index) => {
                        return (
                            <TableContainer
                                key={card.id}
                                component={Paper}
                                style={{
                                    marginBottom: '10px',
                                    width: '350px',
                                }}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow key="type">
                                            <TableCell
                                                align="left"
                                                colSpan={2}
                                                style={{ fontSize: '1.1rem' }}>
                                                <strong>
                                                    {card.brand}{' '}
                                                    {card.id === primaryCard
                                                        ? '(primary)'
                                                        : ''}
                                                </strong>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{ textAlign: 'right' }}>
                                                <SettingsIcon
                                                    style={{ fontSize: 15 }}
                                                    onClick={() =>
                                                        HandleViewInfo(card.id)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="last4">
                                            <TableCell
                                                align="center"
                                                colSpan={3}
                                                style={{
                                                    fontSize: '1.2rem',
                                                    textAlign: 'center',
                                                }}>
                                                **** **** **** {card.last4}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="cardInfo">
                                            <TableCell
                                                align="center"
                                                style={{ fontSize: '0.7rem' }}>
                                                Expire
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ fontSize: '0.7rem' }}>
                                                CVV
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ fontSize: '0.7rem' }}>
                                                Holder Name
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="cardInfo2">
                                            <TableCell
                                                align="center"
                                                style={{ fontSize: '1rem' }}>
                                                {card.exp_month}/{card.exp_year}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ fontSize: '1rem' }}>
                                                <strong>***</strong>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ fontSize: '1rem' }}>
                                                {card.name.toUpperCase()}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    })}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
