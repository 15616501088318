import React from 'react';
import { Grid } from '@material-ui/core';
import PageTitle from '../PageTitle';
import { useHistory } from 'react-router-dom';

const PrivacyPolicyPage = () => {
    let history = useHistory();

    const HandleBack = () => {
        history.push('/settings');
    };

    return (
        <React.Fragment>
            <PageTitle
                title={'Privacy Policy'}
                backButtonCallback={HandleBack}
            />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                <Grid item style={{ width: '100%' }}>
                    Privacy Policy
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
export default PrivacyPolicyPage;
