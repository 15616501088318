import React, { useState, useEffect, useRef } from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// import React, { Component } from 'react'
import { useMap } from '../contexts/MapContext';
import { makeStyles } from '@material-ui/core/styles';
import {
    LOCATION_TYPE_CHARGING_STATION,
    LOCATION_TYPE_EV_CHARGING_STATION,
    LOCATION_TYPE_KIOSK,
    LOCATION_TYPE_PARKING,
} from '../logic/config';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
    // eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const LocationMap = props => {
    const [markers, setMarkers] = useState({});
    const { setMapCoordinates, mapCoordinates } = useMap();
    const [lat, setLat] = useState(undefined);
    const [mapView, setMapView] = useState(null);
    const container = useRef(null);
    function handleClick(locationId) {
        const m = markers[locationId];

        mapView.jumpTo({
            center: m.getLngLat(),
            zoom: 15,
        });

        // const c = this.map.getCanvas()
        const p = mapView.project(m.getLngLat());

        mapView.jumpTo({
            center: mapView.unproject([p.x, p.y]),
            zoom: 15,
        });
        // console.log(m,m.location)
        props.onClick(m.location);
    }
    const geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true,
        },
        trackUserLocation: true,
        fitBoundsOptions: {
            maxZoom: 12,
        },
    });
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: container.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: {
                lng: mapCoordinates.locationLng,
                lat: mapCoordinates.locationLat,
            },
            zoom: mapCoordinates.zoom,
        });
        map.addControl(geolocateControl, 'top-right');
        map.on('moveend', () => props.onDragEnd(map.getBounds()));
        props.onDragEnd(map.getBounds());
        setMapView(map);
    }, []);
    useEffect(() => {
        if (mapView) {
            setMapCoordinates({
                locationLat: mapView.getCenter().lat,
                locationLng: mapView.getCenter().lng,
                zoom: mapView.getZoom(),
            });
            if (props.coordinates.lat !== lat) {
                setLat(props.coordinates.lat);
                mapView.flyTo({
                    center: [props.coordinates.long, props.coordinates.lat],
                    speed: 2,
                    zoom: 15,
                    essential: true,
                });
            }
            let markersView = markers;
            props.locations.forEach(l => {
                if (!markers[l.locationId]) {
                    // const m = new Marker(l.getType().getColor()).setLngLat([l.getPosition().lng, l.getPosition().lat]).addTo(map)
                    // m.location = l
                    // m.getElement().addEventListener('click', () => handleClick(l.locationId))
                    // m.getElement().style.cursor = 'pointer'

                    let el = document.createElement('div');
                    el.className = 'marker';
                    el.style.cursor = 'pointer';
                    el.style.width = '32px';
                    el.style.height = '48px';
                    let fileName;
                    // eslint-disable-next-line default-case
                    switch (l.getType().getName()) {
                        // case LOCATION_TYPE_CHARGING_STATION:
                        //     fileName = 'cs.png'
                        //     if (l.originalObject.type === "container") {
                        //         fileName = 'ch.png'
                        //     }
                        //     break;
                        // case LOCATION_TYPE_KIOSK:
                        //     fileName = 'pb.png'
                        //     break;
                        case LOCATION_TYPE_EV_CHARGING_STATION:
                            fileName = 'ev.png';
                            break;
                        // case LOCATION_TYPE_PARKING:
                        //     fileName = 'pk.png'
                        //     break;
                        // default:
                        //     fileName = 'ch.png'
                    }
                    el.style.backgroundImage = `url(./images/map-icons/${fileName})`;

                    el.addEventListener('click', () =>
                        handleClick(l.locationId),
                    );
                    const m = new Marker(el).setLngLat([
                        l.getPosition().lng,
                        l.getPosition().lat,
                    ]);
                    m.getElement().addEventListener('click', () =>
                        handleClick(l.locationId),
                    );
                    m.addTo(mapView);
                    markersView[l.locationId] = m;
                    markersView[l.locationId].location = l;
                }
            });
            setMarkers(markersView);

            // console.log(markersView)
            // console.log(markersView[Object.keys(markersView)[Object.keys(markersView).length-1]].location,Object.keys(markersView)[Object.keys(markersView).length-1])
        }
    }, [props.locations, props.coordinates]);

    return (
        <>
            <div
                ref={el => (container.current = el)}
                style={{
                    width: '100%',
                    height: `calc(100% - ${props.paddingTop}px)`,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                }}
            />
        </>
    );
};

export default LocationMap;
