import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../assets/images/charge-logo-gradient.png';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: '#0f1820',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logo: {
        maxWidth: 60,
        margin: 'auto',
    },
    container: {
        padding: 15,
        paddingTop: '5rem',
    },
    fullWidth: {
        width: `calc(100% - 5px)`,
    },
    errorMessage: {
        marginTop: '1rem',
        padding: 5,
        width: '100%',
        textAlign: 'left',
        background: theme.palette.secondary.light,
    },
}));

const ErrorBoundaryView = ({ error }) => {
    const classes = useStyles();

    const HandleBack = () => {
        window.location.replace('/');
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="charge" className={classes.logo} />
                    </Toolbar>
                </AppBar>
                <Container className={classes.container}>
                    <Alert
                        variant="outlined"
                        className={classes.fullWidth}
                        style={{ marginLeft: 0 }}
                        severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Uh oh, there was an error in the component!
                    </Alert>
                    {/*<h1>⚠️ Error ⚠️</h1>*/}
                    {/*<p><strong>Uh oh, there was an error in the component!</strong></p>*/}
                    <Button
                        className={classes.fullWidth}
                        style={{ marginTop: '1rem' }}
                        variant="contained"
                        onClick={HandleBack}
                        color={'primary'}>
                        Return to home page
                    </Button>
                    <Accordion style={{ marginTop: '1rem' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>View error message</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" component="p">
                                <code>{error.message}</code>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/*<Grid container className={classes.fullWidth} direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">*/}
                    {/*    <Grid item className={classes.errorMessage}>*/}
                    {/*        <Typography variant="h6">*/}
                    {/*            Error message:*/}
                    {/*        </Typography>*/}
                    {/*        <Typography variant="body2" component="p">*/}
                    {/*           <code>{error.message}</code>*/}
                    {/*        </Typography>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </Container>
            </div>
        </React.Fragment>
    );
};
export default ErrorBoundaryView;
