import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PageTitle from '../PageTitle';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    },
})(MuiTableCell);

export default function CheckBrowsersPage() {
    let history = useHistory();
    const classes = useStyles();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            history.push('/login');
        }
    }, [history, currentUser]);

    const HandleBack = () => {
        history.push('/settings');
    };

    const DetectBrowser = () => {
        let test = function (regexp) {
            return regexp.test(window.navigator.userAgent);
        };
        switch (true) {
            case test(/edg/i):
                return 'Microsoft Edge';
            case test(/trident/i):
                return 'Microsoft Internet Explorer';
            case test(/firefox|fxios/i):
                return 'Mozilla Firefox';
            case test(/opr\//i):
                return 'Opera';
            case test(/ucbrowser/i):
                return 'UC Browser';
            case test(/samsungbrowser/i):
                return 'Samsung Browser';
            case test(/chrome|chromium|crios/i):
                return 'Google Chrome';
            case test(/safari/i):
                return 'Apple Safari';
            default:
                return 'Other';
        }
    };

    return (
        <React.Fragment>
            <PageTitle
                title={'Check browser'}
                backButtonCallback={HandleBack}
            />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                <Grid item style={{ width: '100%' }}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table">
                            <TableBody>
                                <TableRow key={'checkBrowser'}>
                                    <TableCell align="left">
                                        <strong>Detected browser</strong>
                                    </TableCell>
                                    <TableCell align="right" colSpan={3}>
                                        {DetectBrowser()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
