import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import KioskInfoPagePartial from './partials/infoPage/KioskInfoPagePartial';
import ChargingStationInfoPagePartial from './partials/infoPage/ChargingStationInfoPagePartial';
import EvChargingStationInfoPagePartial from './partials/infoPage/EvChargingStationInfoPagePartial';
import ParkingInfoPagePartial from './partials/infoPage/ParkingInfoPagePartial';
import {
    LOCATION_TYPE_CHARGING_STATION,
    LOCATION_TYPE_EV_CHARGING_STATION,
    LOCATION_TYPE_KIOSK,
    LOCATION_TYPE_PARKING,
} from '../../logic/config';

export default function LocationInfoPage() {
    let location = useLocation();
    const [locationObject, setLocationObject] = useState(null);
    useEffect(() => {
        if (location.state?.location) {
            setLocationObject(location.state.location);
        }
    }, [location, setLocationObject]);

    const RenderPartial = type => {
        switch (type) {
            case LOCATION_TYPE_KIOSK:
                return (
                    <KioskInfoPagePartial
                        location={locationObject.originalObject}
                    />
                );
            case LOCATION_TYPE_CHARGING_STATION:
                return (
                    <ChargingStationInfoPagePartial
                        location={locationObject.originalObject}
                    />
                );
            case LOCATION_TYPE_EV_CHARGING_STATION:
                return (
                    <EvChargingStationInfoPagePartial
                        location={locationObject.originalObject}
                    />
                );
            case LOCATION_TYPE_PARKING:
                return (
                    <ParkingInfoPagePartial
                        location={locationObject.originalObject}
                    />
                );
            default:
                return <React.Fragment />;
        }
    };

    return (
        <React.Fragment>
            {locationObject && RenderPartial(locationObject?.type?.name)}
        </React.Fragment>
    );
}
