import React, { useState, useEffect } from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import MainMenuBar from './components/MainMenu';
import { AuthProvider } from './contexts/AuthContext';
import { MapProvider } from './contexts/MapContext';
import { Alert, AlertTitle } from '@material-ui/lab';
import config from './logic/config';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import { onMessageListener } from './firebase';
import AddToHomescreen from 'react-add-to-homescreen';
import Home from './components/Home';
import ScanPage from './components/scan/ScanPage';
import ConfirmPage from './components/scan/ConfirmPage';
import PrivateRoute from './components/PrivateRoute';
import OrdersPage from './components/orders/OrdersPage';
import OrderInfoPage from './components/orders/OrderInfoPage';
import CardsPage from './components/cards/CardsPage';
import AddCardPage from './components/cards/AddCardPage';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import MessagesPage from './components/messages/MessagesPage';
import MessageInfoPage from './components/messages/MessageInfoPage';
import HelpPage from './components/help/HelpPage';
import LeaseResultPage from './components/LeaseResultPage';
import SettingsPage from './components/settings/SettingsPage';
import PrivacyPolicyPage from './components/settings/PrivacyPolicyPage';
import TermsAndConditionPage from './components/settings/TermsAndConditionPage';
import CheckBrowsersPage from './components/settings/CheckBrowsersPage';
import AccountPage from './components/settings/AccountPage';
import AccountConnectToBusinessPage from './components/settings/AccountConnectToBusinessPage';
import SignUpBusiness from './components/SignUpBusiness';
import CheckDeviceToken from './components/settings/DeviceToken';
import SignUp from './components/SignUp';
import Login from './components/Login';
import LoginBusiness from './components/LoginBusiness';
import TestPushNotifications from './components/settings/TestPushNotifications';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardInfoPage from './components/cards/CardInfoPage';
import LocationInfoPage from './components/locations/LocationInfoPage';
import SubscriptionsPage from './components/settings/SubscriptionsPage';
import { DetectBrowser } from './logic/utils';
import { RoutesEnum } from './enums/routes.enums';
const stripePromise = loadStripe(config.stripePk as string);

const padding = 15;
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: 9999,
        backgroundColor: 'rgba(14,21,66,0.85)',
    },
    container: {
        padding,
        paddingTop: 0,
        paddingBottom: 0,
        '& .mapboxgl-control-container': {
            position: 'absolute',
            bottom: '150px',
            right: '25px',
        },
    },
    pushNotification: {
        position: 'absolute',
        top: 'calc(50% - 60px)',
        left: '10px',
        width: 'calc(100% - 20px)',
        zIndex: 9999,
        backgroundColor: '#353e47',
    },
}));

function App() {
    const [showNotification, setShowNotification] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isMapView, setIsMapView] = useState(true);
    const [isShowAddToHomeScreen, setIsShowAddToHomeScreen] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        // if (messaging) {
        //     messaging.getToken().then((data) => {
        //         setToken(data)
        //     })
        //     if (token.length > 0) {
        //         console.log("Token: "+token)
        //     }
        // }

        if (DetectBrowser() === 'Apple Safari') {
            setIsShowAddToHomeScreen(true);
        }
    }, [setIsShowAddToHomeScreen]);

    onMessageListener?.()
        .then(payload => {
            setShowNotification(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
        })
        .catch(err =>
            console.error('Failed to receive push notification: ', err),
        );

    const HandleCloseNotification = () => {
        setShowNotification(false);
        setNotification({ title: '', body: '' });
    };

    const HandleAddToHomeScreen = () => {
        alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
    };

    return (
        <React.Fragment>
            <Router>
                <AuthProvider>
                    <MapProvider>
                        <MainMenuBar
                            isMapView={isMapView}
                            setIsMapView={setIsMapView}
                        />
                        <Container className={classes.container}>
                            {showNotification && (
                                <React.Fragment>
                                    <Backdrop
                                        className={classes.backdrop}
                                        open={showNotification}
                                    />
                                    <Alert
                                        className={classes.pushNotification}
                                        variant="filled"
                                        onClose={HandleCloseNotification}
                                        severity="info"
                                        // show={'false'}
                                    >
                                        <AlertTitle>
                                            {notification.title}
                                        </AlertTitle>
                                        {notification.body}
                                    </Alert>
                                </React.Fragment>
                            )}
                            <Switch>
                                <PrivateRoute exact path={RoutesEnum.Home}>
                                    <Home
                                        isMapView={isMapView}
                                        setIsMapView={setIsMapView}
                                    />
                                </PrivateRoute>
                                <PrivateRoute exact path={RoutesEnum.Cards}>
                                    <CardsPage />
                                </PrivateRoute>
                                <PrivateRoute exact path={RoutesEnum.AddCard}>
                                    <Elements stripe={stripePromise}>
                                        <AddCardPage />
                                    </Elements>
                                </PrivateRoute>
                                <PrivateRoute exact path={RoutesEnum.CardInfo}>
                                    <Elements stripe={stripePromise}>
                                        <CardInfoPage />
                                    </Elements>
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.Orders}>
                                    <OrdersPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.OrderInfo}>
                                    <OrderInfoPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.Messages}>
                                    <MessagesPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.MessagesInfo}>
                                    <MessageInfoPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.Help}>
                                    <HelpPage />
                                </PrivateRoute>
                                <PrivateRoute exact path={RoutesEnum.Settings}>
                                    <SettingsPage />
                                </PrivateRoute>

                                <PrivateRoute
                                    exact
                                    path={RoutesEnum.PrivacyPolicy}>
                                    <PrivacyPolicyPage />
                                </PrivateRoute>
                                <PrivateRoute
                                    exact
                                    path={RoutesEnum.TermsAndConditions}>
                                    <TermsAndConditionPage />
                                </PrivateRoute>
                                <PrivateRoute exact path={RoutesEnum.Account}>
                                    <AccountPage />
                                </PrivateRoute>
                                <PrivateRoute
                                    exact
                                    path={RoutesEnum.AccountConnectToBuisness}>
                                    <AccountConnectToBusinessPage />
                                </PrivateRoute>
                                <PrivateRoute
                                    exact
                                    path={RoutesEnum.CheckBrowser}>
                                    <CheckBrowsersPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.Subscriptions}>
                                    <SubscriptionsPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.DeviceToken}>
                                    <CheckDeviceToken />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={RoutesEnum.TestPushNotifications}>
                                    <TestPushNotifications />
                                </PrivateRoute>

                                <Route exact path={RoutesEnum.Login}>
                                    <Login />
                                </Route>
                                <Route exact path={RoutesEnum.LoginBuisness}>
                                    <LoginBusiness />
                                </Route>
                                <Route exact path={RoutesEnum.SignupBuisness}>
                                    <SignUpBusiness />
                                </Route>
                                <Route exact path={RoutesEnum.SignUp}>
                                    <SignUp />
                                </Route>

                                <Route exact path={RoutesEnum.LogOut}>
                                    <Redirect
                                        to={{
                                            pathname: RoutesEnum.Login,
                                        }}
                                    />
                                </Route>

                                <PrivateRoute path={RoutesEnum.Scan}>
                                    <ScanPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.Confirm}>
                                    <ConfirmPage />
                                </PrivateRoute>
                                <PrivateRoute path={RoutesEnum.LeaseResult}>
                                    <LeaseResultPage />
                                </PrivateRoute>

                                <PrivateRoute
                                    exact
                                    path={RoutesEnum.LocationInfo}>
                                    <LocationInfoPage />
                                </PrivateRoute>
                                <Route path="*">
                                    <Redirect
                                        to={{
                                            pathname: RoutesEnum.Home,
                                        }}
                                    />
                                </Route>
                            </Switch>
                        </Container>
                    </MapProvider>
                </AuthProvider>
            </Router>
            {isShowAddToHomeScreen && (
                <AddToHomescreen
                    icon={'/images/apple-icon-180.png'}
                    title={'Install this app'}
                    onAddToHomescreenClick={HandleAddToHomeScreen}
                />
            )}
        </React.Fragment>
    );
}

export default App;
