import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, Switch } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';
import PageTitle from '../PageTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
    btnPrimary: {
        width: `100%`,
        height: '3rem',
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    },
}));

const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function SubscriptionsPage() {
    let history = useHistory();
    const { currentUser } = useAuth();
    const classes = useStyles();
    const [state, setState] = React.useState({
        checkedChargingStations: true,
        checkedChargingContainers: true,
        checkedEvChargingStations: true,
        checkedPowerBanks: true,
        checkedParking: true,
    });

    useEffect(() => {
        if (!currentUser) {
            history.push('/login');
        }
    }, [history, currentUser]);

    const HandleBack = () => {
        history.push('/');
    };

    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <React.Fragment>
            <PageTitle
                title={'Subscriptions'}
                backButtonCallback={HandleBack}
            />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                <Grid item style={{ width: '100%' }}>
                    <List dense={true}>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Charging Stations</strong>
                            </ListItemText>
                            <ListItemText style={{ textAlign: 'right' }}>
                                <IOSSwitch
                                    checked={state.checkedChargingStations}
                                    onChange={handleChange}
                                    name="checkedChargingStations"
                                />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Charging Hubs</strong>
                            </ListItemText>
                            <ListItemText style={{ textAlign: 'right' }}>
                                <IOSSwitch
                                    checked={state.checkedChargingContainers}
                                    onChange={handleChange}
                                    name="checkedChargingContainers"
                                />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>EV Charging Stations</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <IOSSwitch
                                    checked={state.checkedEvChargingStations}
                                    onChange={handleChange}
                                    name="checkedEvChargingStations"
                                />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>PowerBanks</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <IOSSwitch
                                    checked={state.checkedPowerBanks}
                                    onChange={handleChange}
                                    name="checkedPowerBanks"
                                />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Parking</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <IOSSwitch
                                    checked={state.checkedParking}
                                    onChange={handleChange}
                                    name="checkedParking"
                                />
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
