import { Button, Grid, Link, TextField } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import config from '../logic/config';
import {
    CheckReferralCode,
    CheckUser,
    GetMerchantInfo,
    GetUserInfo,
    UpdateUserProfile,
} from '../logic/network';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from '../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    fullWidth: theme.fullWidth,
    showInfo: {
        maxWidth: '1250px',
        position: 'absolute',
        width: 'calc(100% - 35px)',
        bottom: '40px',
    },
}));

const btnPrimary = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
};

export default function SignUp() {
    const { login, updateUserInfo } = useAuth();
    let { referralCodeParam } = useParams();
    const { state } = useLocation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showVerificationField, setShowVerificationField] = useState(false);
    const [error, setError] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [nickName, setNickName] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [disableResentOTP, setDisableResentOTP] = useState(false);
    const [countResentOTP, setCountResentOTP] = useState(
        config.resendOTPTimeout,
    );

    const setupResentOTP = () => {
        if (disableResentOTP === true) {
            return;
        }
        setDisableResentOTP(true);
        let value = config.resendOTPTimeout;
        setCountResentOTP(value);
        let timer = setInterval(() => {
            if (value > 0) {
                value--;
                setCountResentOTP(value);
            } else {
                return null;
            }
        }, 1000);

        setTimeout(() => {
            setDisableResentOTP(false);
            clearInterval(timer);
        }, config.resendOTPTimeout * 1000);
    };

    useEffect(() => {
        if (referralCodeParam) {
            setReferralCode(referralCodeParam);
        }
    }, [setReferralCode, referralCodeParam]);

    const HandleSignUp = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        if (phoneNumber.length === 0) {
            setLoading(false);
            return;
        }

        // 1. Check user
        try {
            const checkUserResp = await CheckUser(phoneNumber);
            if (checkUserResp.data?.code === 200) {
                setError('User already exists!');
                setLoading(false);
                return;
            }
        } catch (err) {
            setError('Failed to check user');
            setShowVerificationField(false);
            console.error(err);
            setLoading(false);
            return;
        }

        // 2. Check referral
        if (referralCode.length !== 0) {
            try {
                const checkReferralCodeResp = await CheckReferralCode(
                    referralCode,
                );
                if (checkReferralCodeResp.data?.code !== 200) {
                    setError('Invalid referral Code!');
                    setLoading(false);
                    return;
                }
            } catch (err) {
                setError('Failed to check referral code!');
                setShowVerificationField(false);
                console.error(err);
                setLoading(false);
                return;
            }
        }

        // 3. Create user
        try {
            window.confirmationResult = await login(phoneNumber);
            setupResentOTP();
            setShowVerificationField(true);
        } catch (err) {
            if (err.message) {
                setError(err.message);
            } else {
                setError('Failed to login');
            }
            setShowVerificationField(false);
            console.error(err);
            setLoading(false);
            return;
        }

        setLoading(false);
    };

    const HandleVerification = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            // 4. Login user
            const result = await window.confirmationResult.confirm(
                verificationCode,
            );
            let user = result.user;
            // let firebaseToken = await user.getIdToken()
            // updateUserInfo({firebaseToken: firebaseToken})
            const respUserInfo = await GetUserInfo();
            if (respUserInfo.data?.user) {
                respUserInfo.data.user.uid = user.uid;
                // respUserInfo.data.user.firebaseToken = firebaseToken
                respUserInfo.data.user.merchantName = null;
                try {
                    if (respUserInfo.data.user.assigned_merchant) {
                        const merchantInfo = await GetMerchantInfo(
                            respUserInfo.data.user.assigned_merchant,
                        );
                        if (merchantInfo.data?.code === 1) {
                            respUserInfo.data.user.merchantName =
                                merchantInfo.data.merchant.name;
                        }
                    }
                } catch (err) {
                    console.error(err);
                }

                updateUserInfo(respUserInfo.data.user);
            } else {
                setError('Failed to load user data.');
                setLoading(false);
                return;
            }
            let userId = respUserInfo.data.user.id;

            // 5. Update user profile.
            try {
                const respUpdateUser = await UpdateUserProfile(
                    userId,
                    nickName,
                );
                if (respUpdateUser.data?.code !== 1) {
                    setError('Failed to update user profile.');
                    setLoading(false);
                    return;
                }
            } catch (err) {
                setError('Failed to update user');
                setShowVerificationField(false);
                console.error(err);
                setLoading(false);
                return;
            }
            setRedirectToReferrer(true);
        } catch (err) {
            setError(err.message);
            console.log(err);
        }
        setLoading(false);
    };

    const HandleChange = value => {
        setPhoneNumber(value.replace(/[^0-9+]/g, ''));
    };

    const HandleChangeVerificationCode = value => {
        setVerificationCode(value);
    };

    const HandleChangeReferralCode = value => {
        setReferralCode(value);
    };

    const HandleChangeNickname = value => {
        setNickName(value);
    };

    const HandleKeyPress = async e => {
        if (e.key === 'Enter') {
            await HandleSignUp(e);
        }
    };

    if (redirectToReferrer === true) {
        return <Redirect to={state?.from || '/'} />;
    }

    return (
        <React.Fragment>
            <form
                className={classes.formRoot}
                noValidate
                autoComplete="off"
                onSubmit={e => {
                    e.preventDefault();
                }}>
                {loading && (
                    <LinearProgress
                        className={classes.fullWidth}
                        color="primary"
                    />
                )}
                {error && (
                    <Alert
                        variant="outlined"
                        className={classes.fullWidth}
                        severity="error"
                        onClose={() => {
                            setError(null);
                        }}>
                        {error}
                    </Alert>
                )}
                <h1 style={{ width: '100%', wordBreak: 'break-word' }}>
                    Welcome
                </h1>
                {!showVerificationField && (
                    <React.Fragment>
                        <MuiPhoneNumber
                            onlyCountries={config.phoneCountryCodes}
                            className={classes.fullWidth}
                            defaultCountry={'us'}
                            label="Phone number"
                            value={phoneNumber}
                            onChange={HandleChange}
                            onKeyPress={HandleKeyPress}
                        />
                        <TextField
                            className={classes.fullWidth}
                            id="standard-basic"
                            value={nickName}
                            onChange={e => {
                                HandleChangeNickname(e.target.value);
                            }}
                            label="Nickname"
                        />
                        <TextField
                            className={classes.fullWidth}
                            id="standard-basic"
                            value={referralCode}
                            onChange={e => {
                                HandleChangeReferralCode(e.target.value);
                            }}
                            label="Referral code (optional)"
                        />
                        <Button
                            id={'sign-in-button'}
                            className={classes.fullWidth}
                            onClick={HandleSignUp}
                            style={btnPrimary}>
                            Sign Up
                        </Button>
                    </React.Fragment>
                )}
                {showVerificationField && (
                    <React.Fragment>
                        <TextField
                            className={classes.fullWidth}
                            id="verificationCode"
                            label="Verification code"
                            value={verificationCode}
                            onChange={e =>
                                HandleChangeVerificationCode(e.target.value)
                            }
                        />
                        <Button
                            disabled={loading}
                            className={classes.fullWidth}
                            onClick={HandleVerification}
                            variant="contained"
                            color={'primary'}>
                            Verify
                        </Button>
                        <Button
                            id={'resend-otp-button'}
                            disabled={disableResentOTP}
                            className={classes.fullWidth}
                            onClick={HandleSignUp}
                            variant="contained"
                            color={'primary'}>
                            Resend OTP{' '}
                            {disableResentOTP && `(${countResentOTP})`}
                        </Button>
                    </React.Fragment>
                )}

                <Grid
                    container
                    className={classes.fullWidth}
                    direction="row"
                    wrap={'wrap'}
                    alignItems="center"
                    justify="center">
                    <Grid item style={{ width: '100%', textAlign: 'center' }}>
                        or <Link href={'/login'}>Login</Link>
                    </Grid>
                </Grid>
                <div id="firebase-recapcha" style={{ display: 'none' }} />
            </form>
            {/*{showInstallInfo &&*/}
            {/*<Alert variant="outlined" className={classes.showInfo} severity="info" onClose={() => {setShowInstallInfo(null)}}>*/}
            {/*    To install this app press "Share" and select "Add to Home Screen"*/}
            {/*</Alert>*/}
            {/*}*/}
        </React.Fragment>
    );
}
