import { Button, TextField, Link, Grid } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import config from '../logic/config';
import {
    GetMerchantByReferralCode,
    GetUserInfo,
    UpdateAssignedMerchant,
} from '../logic/network';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from '../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    fullWidth: theme.fullWidth,
    showInfo: {
        maxWidth: '1250px',
        position: 'absolute',
        width: 'calc(100% - 35px)',
        bottom: '40px',
    },
}));

const btnPrimary = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
};

export default function LoginBusiness() {
    const { login, updateUserInfo } = useAuth();
    const { state } = useLocation();
    let history = useHistory();
    let { accessCodeParam } = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showVerificationField, setShowVerificationField] = useState(false);
    const [error, setError] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [signUpURL, setSignUpURL] = useState('/signup/business');
    const [merchantId, setMerchantId] = useState(null);
    const [merchantName, setMerchantName] = useState(null);
    const [accessCode, setAccessCode] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showAccessCodeField, setShowAccessCodeField] = useState(true);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [disableResentOTP, setDisableResentOTP] = useState(false);
    const [countResentOTP, setCountResentOTP] = useState(
        config.resendOTPTimeout,
    );

    const setupResentOTP = () => {
        if (disableResentOTP === true) {
            return;
        }

        setDisableResentOTP(true);
        let value = config.resendOTPTimeout;
        setCountResentOTP(value);
        let timer = setInterval(() => {
            if (value > 0) {
                value--;
                setCountResentOTP(value);
            } else {
                return null;
            }
        }, 1000);

        setTimeout(() => {
            setDisableResentOTP(false);
            clearInterval(timer);
        }, config.resendOTPTimeout * 1000);
    };

    useEffect(() => {
        const asyncLoadFunction = async () => {
            if (accessCodeParam) {
                setAccessCode(accessCodeParam);
                let merchantInfoResult = await validateMerchantAccessCode(
                    accessCodeParam,
                );
                if (merchantInfoResult !== null) {
                    setShowAccessCodeField(false);
                }
            }
        };
        asyncLoadFunction();
    }, [setAccessCode, setShowAccessCodeField, accessCodeParam]);

    const validateMerchantAccessCode = async accessCode => {
        let result = null;
        try {
            const merchantInfoResp = await GetMerchantByReferralCode(
                accessCode,
            );
            if (merchantInfoResp.data?.code !== 1) {
                setError('Invalid referral code!');
                setLoading(false);
                result = null;
            } else {
                if (merchantInfoResp.data.merchantId !== null) {
                    setMerchantId(merchantInfoResp.data.merchantId);
                    setMerchantName(merchantInfoResp.data.merchantName);
                    result = merchantInfoResp.data.merchantId;
                    setSignUpURL(`/signup/business/${accessCodeParam}`);
                } else {
                    setError('Invalid access code!');
                    setLoading(false);
                    result = null;
                }
            }
        } catch (err) {
            setError('Failed to validate access code!');
            setShowVerificationField(false);
            console.error(err);
            setLoading(false);
            result = null;
        }

        return result;
    };

    const HandleLogin = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        if (phoneNumber.length === 0) {
            setLoading(false);
            return;
        }

        try {
            if (merchantId === null) {
                let merchantInfoResult = await validateMerchantAccessCode(
                    accessCode,
                );
                if (merchantInfoResult === null) {
                    return;
                }
            }
            window.confirmationResult = await login(phoneNumber);
            setupResentOTP();
        } catch (err) {
            if (err.message) {
                setError(err.message);
            } else {
                setError('Failed to login');
            }
            setShowVerificationField(false);
            console.error(err);
        }
        setShowVerificationField(true);
        setLoading(false);
    };

    const HandleVerification = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const result = await window.confirmationResult.confirm(
                verificationCode,
            );
            let user = result.user;
            // let firebaseToken = await user.getIdToken()
            // updateUserInfo({firebaseToken: firebaseToken})
            const respUserInfo = await GetUserInfo();
            if (respUserInfo.data?.user) {
                respUserInfo.data.user.uid = user.uid;
                // respUserInfo.data.user.firebaseToken = firebaseToken
                respUserInfo.data.user.merchantName = null;
                if (merchantName) {
                    respUserInfo.data.user.merchantName = merchantName;
                }
                updateUserInfo(respUserInfo.data.user);
            } else {
                setError('Failed to load user data.');
                setLoading(false);
                return;
            }
            let userId = respUserInfo.data.user.id;

            // 5. Assign user to merchant
            try {
                const assignMerchantResp = await UpdateAssignedMerchant(
                    userId,
                    merchantId,
                );
                if (assignMerchantResp.data?.code !== 1) {
                    setError('Failed to assign merchant!');
                    setLoading(false);
                    return;
                }
            } catch (err) {
                setError('Failed to assign merchant!');
                setShowVerificationField(false);
                console.error(err);
                setLoading(false);
                return;
            }

            setRedirectToReferrer(true);
        } catch (err) {
            setError(err.message);
            console.log(err);
        }
        setLoading(false);
    };

    const HandleChange = value => {
        setPhoneNumber(value.replace(/[^0-9+]/g, ''));
    };

    const HandleChangeVerificationCode = value => {
        setVerificationCode(value);
    };

    const HandleChangeAccessCode = value => {
        setAccessCode(value);
    };

    const HandleKeyPress = async e => {
        if (e.key === 'Enter') {
            await HandleLogin(e);
        }
    };

    const HandleSignUp = () => {
        history.push({
            pathname: signUpURL,
            state: {
                from: state?.from,
            },
        });
    };

    if (redirectToReferrer === true) {
        return <Redirect to={state?.from || '/'} />;
    }

    return (
        <React.Fragment>
            <form
                className={classes.formRoot}
                noValidate
                autoComplete="off"
                onSubmit={e => {
                    e.preventDefault();
                }}>
                {loading && (
                    <LinearProgress
                        className={classes.fullWidth}
                        color="primary"
                    />
                )}
                {error && (
                    <Alert
                        variant="outlined"
                        className={classes.fullWidth}
                        severity="error"
                        onClose={() => {
                            setError(null);
                        }}>
                        {error}
                    </Alert>
                )}
                <h1 style={{ width: '100%', wordBreak: 'break-word' }}>
                    {merchantName ? 'Welcome to ' + merchantName : 'Welcome'}
                </h1>

                {!showVerificationField && (
                    <React.Fragment>
                        <MuiPhoneNumber
                            onlyCountries={config.phoneCountryCodes}
                            className={classes.fullWidth}
                            defaultCountry={'us'}
                            label="Phone number"
                            value={phoneNumber}
                            onChange={HandleChange}
                            onKeyPress={HandleKeyPress}
                        />
                        {showAccessCodeField && (
                            <TextField
                                className={classes.fullWidth}
                                id="standard-basic"
                                value={accessCode}
                                onChange={e => {
                                    HandleChangeAccessCode(e.target.value);
                                }}
                                label="Employee access code"
                            />
                        )}
                        <Button
                            id={'sign-in-button'}
                            className={classes.fullWidth}
                            onClick={HandleLogin}
                            style={btnPrimary}>
                            Sign in
                        </Button>
                    </React.Fragment>
                )}
                {showVerificationField && (
                    <React.Fragment>
                        <TextField
                            className={classes.fullWidth}
                            id="verificationCode"
                            label="Verification code"
                            value={verificationCode}
                            onChange={e =>
                                HandleChangeVerificationCode(e.target.value)
                            }
                        />
                        <Button
                            disabled={loading}
                            className={classes.fullWidth}
                            onClick={HandleVerification}
                            variant="contained"
                            color={'primary'}>
                            Verify
                        </Button>
                        <Button
                            id={'resend-otp-button'}
                            disabled={disableResentOTP}
                            className={classes.fullWidth}
                            onClick={HandleLogin}
                            variant="contained"
                            color={'primary'}>
                            Resend OTP{' '}
                            {disableResentOTP && `(${countResentOTP})`}
                        </Button>
                    </React.Fragment>
                )}

                {/* <Grid container className={classes.fullWidth} direction="row" wrap={"wrap"} alignItems="center" justify="center">
                    <Grid item style={{width: "100%", textAlign: 'center'}}>
                        <Link onClick={HandleSignUp}>SignUp as an employee</Link>
                    </Grid>
                </Grid> */}
                <div id="firebase-recapcha" style={{ display: 'none' }} />
            </form>
            {/*{showInstallInfo &&*/}
            {/*<Alert variant="outlined" className={classes.showInfo} severity="info" onClose={() => {setShowInstallInfo(null)}}>*/}
            {/*    To install this app press "Share" and select "Add to Home Screen"*/}
            {/*</Alert>*/}
            {/*}*/}
        </React.Fragment>
    );
}
