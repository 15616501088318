import React, { useState } from 'react';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    h: {
        marginTop: '10px',
        width: '100%',
        position: 'relative',
    },
    main: {
        position: 'relative',
        '& input': {
            border: 'none',
            height: '30px',
            borderRadius: '5px',
        },
        '& input:focus': {
            fontSsize: '16px',
        },
        '& .react-mapbox-ac-menu': {
            color: 'black',
        },
    },
}));
export function AddressInput(props) {
    const classes = useStyles();
    const mapAccess = {
        // Thanks to SomeSoftwareTeam (https://github.com/SomeSoftwareTeam/some-react-app/blob/acd17860b8b1f51edefa4e18486cc1fb07afff70/src/components/SomeComponent.js)
        mapboxApiAccessToken:
            'pk.eyJ1IjoiY2hhcmdlLWFjY291bnRzIiwiYSI6ImNrNWQzYWFjZTFyeHIzbHFoM3Y5cDlsdm0ifQ.1-jxpSnos65qghwoD1nSTw',
    };
    function _suggestionSelect(result, lat, long, text) {
        props.setCoordinates({ lat, long });
    }
    return (
        <div className={classes.main} style={{ width: '100%', zIndex: 10 }}>
            <form>
                <MapboxAutocomplete
                    style={{ marginTop: '110px' }}
                    publicKey={mapAccess.mapboxApiAccessToken}
                    inputClass={`form-control search ${classes.h}`}
                    onSuggestionSelect={_suggestionSelect}
                    country="us"
                    resetSearch={true}
                    placeholder="Search Address..."
                />
            </form>
        </div>
    );
}
