import { Button, TextField, Link, Grid } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import config from '../logic/config';
import { GetMerchantInfo, GetUserInfo } from '../logic/network';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from '../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    fullWidth: theme.fullWidth,
    showInfo: {
        maxWidth: '1250px',
        position: 'absolute',
        width: 'calc(100% - 35px)',
        bottom: '40px',
    },
}));

const btnPrimary = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
};

// Button style
// const btnSecondary = {
//     background: '#C3C3C3',
//     borderRadius: 3,
//     border: 0,
//     color: 'white',
//
//     boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
// }

export default function Login() {
    const { login, updateUserInfo } = useAuth();
    let history = useHistory();
    const { state } = useLocation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showVerificationField, setShowVerificationField] = useState(false);
    const [error, setError] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [disableResentOTP, setDisableResentOTP] = useState(false);
    const [countResentOTP, setCountResentOTP] = useState(
        config.resendOTPTimeout,
    );

    const setupResentOTP = () => {
        if (disableResentOTP === true) {
            return;
        }
        setDisableResentOTP(true);
        let value = config.resendOTPTimeout;
        setCountResentOTP(value);
        let timer = setInterval(() => {
            if (value > 0) {
                value--;
                setCountResentOTP(value);
            } else {
                return null;
            }
        }, 1000);

        setTimeout(() => {
            setDisableResentOTP(false);
            clearInterval(timer);
        }, config.resendOTPTimeout * 1000);
    };

    const HandleLogin = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        if (phoneNumber.length === 0) {
            setLoading(false);
            return;
        }

        try {
            window.confirmationResult = await login(phoneNumber);
            setupResentOTP();
            setShowVerificationField(true);
        } catch (err) {
            if (err.message) {
                setError(err.message);
            } else {
                setError('Failed to login');
            }
            setShowVerificationField(false);
            console.error(err);
        }
        setLoading(false);
    };

    const HandleVerification = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const result = await window.confirmationResult.confirm(
                verificationCode,
            );
            let user = result.user;
            // let firebaseToken = await user.getIdToken()
            // updateUserInfo({firebaseToken: firebaseToken})
            const respUserInfo = await GetUserInfo();
            console.log('data', respUserInfo.data.user);
            if (respUserInfo.data?.user) {
                respUserInfo.data.user.uid = user.uid;
                // respUserInfo.data.user.firebaseToken = firebaseToken
                respUserInfo.data.user.merchantName = null;
                try {
                    if (respUserInfo.data.user.assigned_merchant) {
                        const merchantInfo = await GetMerchantInfo(
                            respUserInfo.data.user.assigned_merchant,
                        );
                        if (merchantInfo.data?.code === 1) {
                            respUserInfo.data.user.merchantName =
                                merchantInfo.data.merchant.name;
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
                updateUserInfo(respUserInfo.data.user);
                setRedirectToReferrer(true);
            } else {
                setError('Failed to load user data.');
                setLoading(false);
                return;
            }

            // //Update user profile.
            // const respUpdateUser = await UpdateUserProfile(nickName)
            // if (respUpdateUser.data?.code !== 1) {
            //     setError("Failed to update user profile.")
            // }
        } catch (err) {
            setError(err.message);
            console.log(err);
        }
        setLoading(false);
    };

    const HandleChange = value => {
        setPhoneNumber(value.replace(/[^0-9+]/g, ''));
    };

    const HandleChangeVerificationCode = e => {
        e.preventDefault();
        setVerificationCode(e.target.value);
    };

    const HandleKeyPress = async e => {
        if (e.key === 'Enter') {
            await HandleLogin(e);
        }
    };

    if (redirectToReferrer === true) {
        return <Redirect to={state?.from || '/'} />;
    }

    const HandleSignUp = signUpURL => {
        history.push({
            pathname: signUpURL,
            state: {
                from: state?.from,
            },
        });
    };

    return (
        <React.Fragment>
            <form
                className={classes.formRoot}
                noValidate
                autoComplete="off"
                onSubmit={e => {
                    e.preventDefault();
                }}>
                {loading && (
                    <LinearProgress
                        className={classes.fullWidth}
                        color="primary"
                    />
                )}
                {error && (
                    <Alert
                        variant="outlined"
                        className={classes.fullWidth}
                        severity="error"
                        onClose={() => {
                            setError(null);
                        }}>
                        {error}
                    </Alert>
                )}
                <h1 style={{ width: '100%' }}>Welcome</h1>
                {!showVerificationField && (
                    <React.Fragment>
                        <MuiPhoneNumber
                            onlyCountries={config.phoneCountryCodes}
                            className={classes.fullWidth}
                            defaultCountry={'us'}
                            label="Phone number"
                            value={phoneNumber}
                            onChange={HandleChange}
                            onKeyPress={HandleKeyPress}
                        />
                        <Button
                            id={'sign-in-button'}
                            className={classes.fullWidth}
                            onClick={HandleLogin}
                            style={btnPrimary}>
                            Sign in
                        </Button>
                    </React.Fragment>
                )}
                {showVerificationField && (
                    <React.Fragment>
                        <TextField
                            className={classes.fullWidth}
                            id="verificationCode"
                            label="Verification code"
                            value={verificationCode}
                            onChange={HandleChangeVerificationCode}
                        />
                        <Button
                            disabled={loading}
                            className={classes.fullWidth}
                            onClick={HandleVerification}
                            style={btnPrimary}>
                            Verify
                        </Button>
                        <Button
                            id={'resend-otp-button'}
                            disabled={disableResentOTP}
                            className={classes.fullWidth}
                            onClick={HandleLogin}
                            variant="contained"
                            color={'primary'}>
                            Resend OTP{' '}
                            {disableResentOTP && `(${countResentOTP})`}
                        </Button>
                    </React.Fragment>
                )}
                <Grid
                    container
                    className={classes.fullWidth}
                    direction="row"
                    wrap={'wrap'}
                    alignItems="center"
                    justify="center">
                    <Grid item style={{ width: '100%', textAlign: 'center' }}>
                        <Link onClick={() => HandleSignUp('/signup')}>
                            SignUp
                        </Link>
                    </Grid>
                    {/* <Grid item style={{width: "100%", textAlign: 'center'}}>
                        or
                    </Grid>
                    <Grid item style={{width: "100%", textAlign: 'center'}}>
                        <Link onClick={() => HandleSignUp("/signup/business")}>SignUp as an employee</Link>
                    </Grid> */}
                </Grid>
                <div id="firebase-recapcha" style={{ display: 'none' }} />
            </form>
            {/*{showInstallInfo &&*/}
            {/*    <Alert variant="outlined" className={classes.showInfo} severity="info" onClose={() => {setShowInstallInfo(null)}}>*/}
            {/*        To install this app press "Share" and select "Add to Home Screen"*/}
            {/*    </Alert>*/}
            {/*}*/}
            {/* Buttons example */}
            {/*<Button className={classes.fullWidth} onClick={HandleVerification} variant="contained" color={"primary"}>Primary</Button>*/}
            {/*<Button className={classes.fullWidth} onClick={HandleLogin} variant="contained" color={"secondary"}>secondary default</Button>*/}
            {/*<Button className={classes.fullWidth} onClick={HandleLogin} style={btnSecondary}>secondary</Button>*/}
        </React.Fragment>
    );
}
