import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import PageTitle from './PageTitle';

export default function LeaseResultPage() {
    let history = useHistory();
    const location = useLocation();

    const HandleBack = () => {
        history.push('/scan');
    };

    useEffect(() => {
        if (!location.state) {
            history.push('/scan');
        }
    }, [location, history]);

    const HandleCheckUsage = () => {
        history.push({
            pathname: '/order/info',
            state: {
                orderId: location.state.orderid,
            },
        });
    };

    return (
        <React.Fragment>
            <PageTitle title={'Lease result'} backButtonCallback={HandleBack} />
            {location.state && (
                <React.Fragment>
                    <Grid
                        container
                        direction="row"
                        wrap={'wrap'}
                        alignItems="center"
                        justify="center">
                        <Grid item>
                            <h3>
                                Powerbank ejected, <br />
                                Please collect to use
                            </h3>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        wrap={'wrap'}
                        alignItems="center"
                        justify="center"
                        style={{ paddingTop: '1rem' }}>
                        <Grid item>
                            <Button
                                onClick={HandleCheckUsage}
                                variant="contained"
                                color={'primary'}>
                                Check Usage
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
