import { createTheme } from '@material-ui/core/styles';
import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';

const theme = createTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#0f1820',
        },
        primary: {
            light: '#77ffc5',
            main: '#38d894',
            dark: '#00a566',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#353e47',
            main: '#0f1820',
            dark: '#000000',
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Poppins, Arial',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': {
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontDisplay: 'swap',
                    fontWeight: 400,
                    src: `
                    url(${PoppinsRegular.toString()})
                  `,
                },
            },
        },
    },
});

export default theme;
