import React, { useEffect, useState } from 'react';
import PageTitle from '../PageTitle';
import { useHistory } from 'react-router-dom';
import { GetTokens, PushNotification } from '../../logic/network';
import { makeStyles, Button, Modal, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '10px 0',
    },
    modal: {
        backgroundColor: '#253025',
        top: '40%',
        position: 'absolute',
        left: '10%',
        right: '10%',
    },
    modalContainers: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '20px 0',
        alignItems: 'center',
    },
}));

interface ITokensInfo {
    mobile_number: string;
    device_token: string;
    firebase_token: string;
    user_name: string;
}
const btnPrimary = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
};
const modalBtn = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    width: '80%',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
};
const TestPushNotifications = () => {
    const history = useHistory();
    const [pushesInfo, setPushesInfo] = useState<ITokensInfo[] | null>(null);
    const [modalIndex, setModalIndex] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [pushBody, setPushBody] = useState('');
    const [pushTitle, setPushTitle] = useState('');

    const handleInputCahange = (isBody: boolean, value: string) => {
        isBody ? setPushBody(value) : setPushTitle(value);
    };

    const handleClose = () => {
        setModalIndex(null);
        setPushBody('');
        setPushTitle('');
        setOpen(false);
    };
    const classes = useStyles();
    const HandleBack = () => {
        history.push('/settings');
    };
    useEffect(() => {
        void GetTokens().then(res => {
            setPushesInfo(res.data);
        });
    }, []);

    const sendNotification = (info: { token: string }) => {
        void PushNotification({
            to: info.token,
            notification: {
                title: pushTitle,
                body: pushBody,
                sound: 'default',
            },
        });
        setPushBody('');
        setPushTitle('');
    };

    const handleOpen = (i: number) => {
        setOpen(true);
        setModalIndex(i);
    };
    return (
        <React.Fragment>
            <PageTitle title={'Test Push'} backButtonCallback={HandleBack} />
            <div className={classes.container}>
                {pushesInfo?.map((el, i) => {
                    return (
                        <div className={classes.row} key={i}>
                            <p>{el.user_name}</p>
                            <p>{el.mobile_number}</p>
                            <Button
                                onClick={() => handleOpen(i)}
                                style={btnPrimary}>
                                Send Notification
                            </Button>
                        </div>
                    );
                })}
            </div>
            {open && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className={classes.modal}>
                        <div className={classes.modalContainers}>
                            <TextField
                                id="standard-basic"
                                value={pushTitle}
                                onChange={e => {
                                    handleInputCahange(false, e.target.value);
                                }}
                                label="Title"
                            />
                        </div>
                        <div className={classes.modalContainers}>
                            <TextField
                                id="standard-basic"
                                value={pushBody}
                                onChange={e => {
                                    handleInputCahange(true, e.target.value);
                                }}
                                label="Body"
                            />
                        </div>

                        <div className={classes.modalContainers}>
                            <Button
                                disabled={!(pushTitle && pushBody)}
                                onClick={() =>
                                    sendNotification({
                                        token: pushesInfo![modalIndex as number]
                                            .device_token,
                                    })
                                }
                                style={modalBtn}>
                                Send Notification to{' '}
                                {pushesInfo![modalIndex as number].user_name}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};
export default TestPushNotifications;
