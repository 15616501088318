import config from './config';
import { IsValidQrPrefix } from './utils';

class LocationType {
    constructor(type) {
        this.name = type;
        this.color = config.locationTypesDefaultColor;
        if (config.locationTypesColors[type.toLowerCase()] !== null) {
            this.color = config.locationTypesColors[type.toLowerCase()];
        }
    }

    getName() {
        return this.name;
    }

    getColor() {
        return this.color;
    }
}

export const CreateLocationTypeByPrefix = prefix => {
    console.log('prefix', prefix);
    let locationType = null;
    if (IsValidQrPrefix(prefix)) {
        locationType = new LocationType(
            config.qrCodeTypeMapping[prefix.toUpperCase()],
        );
    }

    return locationType;
};

class Location {
    constructor(originalObject, type, lat, lng) {
        this.locationId = type + '#' + originalObject.id;
        this.id = originalObject.id;
        this.type = new LocationType(type);
        this.coordinates = {
            lat,
            lng,
        };
        this.originalObject = originalObject;
    }

    getPosition() {
        return this.coordinates;
    }

    getType() {
        return this.type;
    }
}

export default Location;
