import React, { useEffect, useState } from 'react';
import {
    LOCATION_TYPE_CHARGING_STATION,
    LOCATION_TYPE_EV_CHARGING_STATION,
    LOCATION_TYPE_KIOSK,
    LOCATION_TYPE_PARKING,
} from '../../logic/config';
import KioskConfirmPagePartial from './partials/confirmPage/KioskConfirmPagePartial';
import { useHistory, useParams } from 'react-router-dom';
import { IsValidQrPrefix } from '../../logic/utils';
import Alert from '@material-ui/lab/Alert';
import PageTitle from '../PageTitle';
import { CreateLocationTypeByPrefix } from '../../logic/locations';
import ChargingStationConfirmPagePartial from './partials/confirmPage/ChargingStationConfirmPagePartial';
import EvChargingStationConfirmPagePartial from './partials/confirmPage/EvChargingStationConfirmPagePartial';
import ParkingConfirmPagePartial from './partials/confirmPage/ParkingConfirmPagePartial';

export default function ConfirmPage() {
    let { locationId, referralCodeParam } = useParams();
    const history = useHistory();
    const [locationType, setLocationType] = useState(null);
    const [error, setError] = React.useState(null);
    const [warning, setWarning] = React.useState(null);

    useEffect(() => {
        let locationIdPrefix = locationId.substring(0, 4);
        if (IsValidQrPrefix(locationIdPrefix)) {
            let locationTypeObject =
                CreateLocationTypeByPrefix(locationIdPrefix);
            setLocationType(locationTypeObject);
        } else {
            setError('Unknown location type. Try to scan again!');
        }
    }, [locationId, referralCodeParam]);

    const RenderPartial = type => {
        switch (type) {
            case LOCATION_TYPE_KIOSK:
                return (
                    <KioskConfirmPagePartial
                        kioskId={locationId}
                        referralCodeParam={referralCodeParam}
                    />
                );
            case LOCATION_TYPE_CHARGING_STATION:
                return (
                    <ChargingStationConfirmPagePartial
                        locationId={locationId.substring(4)}
                    />
                );
            case LOCATION_TYPE_EV_CHARGING_STATION:
                return (
                    <EvChargingStationConfirmPagePartial
                        locationId={locationId}
                    />
                );
            case LOCATION_TYPE_PARKING:
                return <ParkingConfirmPagePartial locationId={locationId} />;
            default:
                setWarning(`Location type: "${type}" not implemented yet!`);
                setLocationType(null);
        }
    };

    const HandleBack = () => {
        history.push('/scan');
    };

    return (
        <React.Fragment>
            {locationType !== null && RenderPartial(locationType.name)}
            {locationType === null && (
                <React.Fragment>
                    {error && (
                        <Alert
                            variant="outlined"
                            severity="error"
                            onClose={() => {
                                setError(null);
                            }}>
                            {error}
                        </Alert>
                    )}
                    {warning && (
                        <Alert
                            variant="outlined"
                            severity="warning"
                            onClose={() => {
                                setWarning(null);
                            }}>
                            {warning}
                        </Alert>
                    )}
                    <PageTitle
                        title={'Confirm'}
                        backButtonCallback={HandleBack}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
