import React, { useEffect, useState } from 'react';
import LocationMap from './LocationMap';
// eslint-disable-next-line
import { loadLocationsByCoordinates, AddDeviceToken } from '../logic/network';
// eslint-disable-next-line
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { useHistory } from 'react-router-dom';
// import howToImage from "../assets/images/charge-pb-howto@2x.png";
import Box from '@material-ui/core/Box';
import { Capacitor } from '@capacitor/core';
import { useAuth } from '../contexts/AuthContext';
import { LocationsListView } from './LocationsListView';
import { AddressInput } from './AddressInput';
// import Backdrop from "@material-ui/core/Backdrop";
// eslint-disable-next-line
import { PushNotifications } from '@capacitor/push-notifications';
import { Alert } from '@material-ui/lab';
// import AwesomeSlider from 'react-awesome-slider';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
// const AutoplaySlider = withAutoplay(AwesomeSlider);
import { RoutesEnum } from '../enums/routes.enums';
import { LngLatBounds } from 'mapbox-gl';

const btnPrimary = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
};

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    btnScan: {
        position: 'absolute',
        bottom: '50px',
        width: 'calc(100% - 30px)',
    },
    howToImage: {
        maxWidth: '350px',
        background: '#0f1820',
    },
    backdrop: {
        zIndex: 9999,
        backgroundColor: 'rgba(14,21,66,0.85)',
    },
    popupLocationInfo: {
        '& > .MuiAlert-message': {
            width: '100%',
        },
        position: 'absolute',
        top: 'calc(30%)',
        left: '10px',
        width: 'calc(100% - 20px)',
        zIndex: 9999,
        backgroundColor: theme.palette.secondary.main,
    },
    popupError: {
        position: 'absolute',
        top: '65px',
        left: '10px',
        width: 'calc(100% - 20px)',
        zIndex: 9999,
    },
    popupLink: {
        color: 'white',
        textDecoration: 'underline',
    },
    primaryBtn: {
        marginTop: '1rem',
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    },
    secondaryBtn: {
        '&:hover, &:active': {
            background: '#C3C3C3',
        },
        marginTop: '1rem',
        background: '#C3C3C3',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
        width: '100%',
    },
}));

interface Homeprops {
    isMapView: boolean;
    setIsMapView: (arg0: boolean) => void;
}

const Home: React.FC<Homeprops> = ({ isMapView, setIsMapView }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    // eslint-disable-next-line
    const classes = useStyles();
    const { setTokenInfo } = useAuth();
    const [coordinates, setCoordinates] = useState({});

    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo')!);

        if (
            Capacitor.isPluginAvailable('PushNotifications') ||
            Capacitor.getPlatform() === 'android'
        ) {
            void PushNotifications.requestPermissions().then(result => {
                console.log('permission');

                if (result.receive === 'granted') {
                    void PushNotifications.register();
                } else {
                    // Show some error
                    console.log('errrrrror');
                }
            });

            void PushNotifications.addListener('registration', token => {
                void AddDeviceToken({
                    mobile_number: userInfo.mobile,
                    device_token: token.value,
                    firebase_token: userInfo.firebaseToken,
                    user_name: userInfo.name,
                });
                Capacitor.getPlatform() === 'android' &&
                    localStorage.setItem('deviceToken', token.value);
                setTokenInfo(token.value);
            });

            void PushNotifications.addListener('registrationError', error => {
                alert('Error on registration: ' + JSON.stringify(error));
            });

            void PushNotifications.addListener(
                'pushNotificationReceived',
                notification => {
                    alert('Push received: ' + JSON.stringify(notification));
                },
            );

            void PushNotifications.addListener(
                'pushNotificationActionPerformed',
                notification => {
                    alert(
                        'Push action performed: ' +
                            JSON.stringify(notification),
                    );
                },
            );
        }
    }, []);

    const handleClick = async (location: any) => {
        // setFocusedLocation(location)
        // setShowLocationPopupInfo(true)
        console.log(location);
        history.push({
            pathname: RoutesEnum.LocationInfo,
            state: {
                location,
            },
        });

        // try {
        //     const resp = await contractorsLocationGet('station', location.id)
        //     setFocusedLocation(resp.data.location)
        //     setError(null)
        //     console.log(focusedLocation)
        // } catch (err) {
        //     console.error(err)
        //     setError(err)
        //     console.log(err)
        // }
    };

    const handleMove = async (bounds: LngLatBounds) => {
        console.log('dfvwefvewrverfvertvretf');
        if (!loading) {
            setLoading(true);
            const locations = await loadLocationsByCoordinates(bounds);
            setLocations(locations as any);
            setLoading(false);
        }
    };

    const HandleScan = async () => {
        history.push('/scan');
    };

    return (
        <React.Fragment>
            {error && (
                <Alert
                    variant="standard"
                    className={classes.popupError}
                    severity="error"
                    onClose={() => {
                        setError(null);
                    }}>
                    {error}
                </Alert>
            )}
            <Grid container justify="center">
                {/* <AutoplaySlider */}
                {/*    play={true} */}
                {/*    cancelOnInteraction={false} */}
                {/*    interval={6000} */}
                {/*    bullets={false} */}
                {/*    organicArrows={false}> */}
                {/*    <div data-src="https://charge-v1-bucket.s3.amazonaws.com/image/banner/20210605/banner2.jpg" /> */}
                {/*    <div data-src="https://charge-v1-bucket.s3.amazonaws.com/image/banner/20210605/banner2.jpg" /> */}
                {/* </AutoplaySlider> */}
                {/* {(showLocationPopupInfo && focusedLocation !== null) &&
                    <LocationInfoPopup onClose={HandleCloseFocusedLocation} isOpen={showLocationPopupInfo} location={focusedLocation} />
                } */}
                {isMapView && <AddressInput setCoordinates={setCoordinates} />}
                {isMapView ? (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        m={1}
                        p={1}
                        bgcolor="background.color"
                        style={{ padding: 0, margin: 0 }}>
                        <LocationMap
                            coordinates={coordinates}
                            paddingTop={50}
                            locations={locations}
                            onClick={handleClick}
                            onDragEnd={handleMove}
                        />
                    </Box>
                ) : (
                    <LocationsListView
                        setIsMapView={setIsMapView}
                        isMapView={isMapView}
                        locations={locations}
                    />
                )}

                {isMapView && (
                    <Button
                        className={classes.btnScan}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={HandleScan}
                        style={btnPrimary}>
                        <CropFreeIcon />
                        Scan
                    </Button>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Home;
