import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PageTitle from '../PageTitle';
import { FormatDate } from '../../logic/utils';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

const TableCell = withStyles({
    root: {
        borderBottom: 'none',
    },
})(MuiTableCell);

export default function MessageInfoPage() {
    let history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const classes = useStyles();
    const { currentUser } = useAuth();

    useEffect(() => {
        setLoading(true);
        setError(null);
        if (!currentUser) {
            history.push('/login');
        }
        if (!location.state) {
            history.push('/messages');
        }
        setMessage(location.state.message);
        setLoading(false);
    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push('/messages');
    };

    return (
        <React.Fragment>
            {loading && (
                <LinearProgress className={classes.fullWidth} color="primary" />
            )}
            {error && (
                <Alert
                    variant="outlined"
                    className={classes.fullWidth}
                    severity="error"
                    onClose={() => {
                        setError(null);
                    }}>
                    {error}
                </Alert>
            )}
            <PageTitle
                title={'Message Detail'}
                backButtonCallback={HandleBack}
            />
            {message && (
                <Grid
                    container
                    direction="row"
                    wrap={'wrap'}
                    alignItems="center"
                    justify="flex-start">
                    <Grid item style={{ width: '100%' }}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small">
                                <TableBody>
                                    <TableRow key={'messageTitle'}>
                                        <TableCell align="center">
                                            <h3>{message.msgTitle}</h3>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'messageDate'}>
                                        <TableCell align="left">
                                            {FormatDate(
                                                message.createdAt * 1000,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'messageBody'}>
                                        <TableCell align="left">
                                            {message.msgBody}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}
