import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';
import PageTitle from '../PageTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { OpenLinkInNewTab } from '../../logic/utils';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
    btnPrimary: {
        width: '100%',
        height: '3rem',
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    },
}));

export default function SettingsPage() {
    let history = useHistory();
    const { currentUser, logout } = useAuth();
    const classes = useStyles();

    useEffect(() => {
        if (!currentUser) {
            history.push('/login');
        }
    }, [history, currentUser]);

    const HandleBack = () => {
        history.push('/');
    };

    const HandleLogout = () => {
        logout();
        history.push({
            pathname: '/logout',
            state: null,
        });
    };

    return (
        <React.Fragment>
            <PageTitle title={'Settings'} backButtonCallback={HandleBack} />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                <Grid item style={{ width: '100%' }}>
                    <List dense={true}>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}
                            onClick={() => {
                                history.push('/settings/account');
                            }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Account</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Version</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                POC v1.0
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}
                            onClick={() => {
                                OpenLinkInNewTab(
                                    'https://www.charge.us/pbprivacy',
                                );
                            }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Privacy Policy</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <Link
                                    className={classes.primaryColor}
                                    to={'https://www.charge.us/pbprivacy'}>
                                    <ArrowForwardIosIcon
                                        style={{ fontSize: 15 }}
                                    />
                                </Link>
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}
                            onClick={() => {
                                OpenLinkInNewTab(
                                    'https://www.charge.us/pbterms',
                                );
                            }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Terms and Condition</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <Link
                                    className={classes.primaryColor}
                                    to={'https://www.charge.us/pbterms'}>
                                    <ArrowForwardIosIcon
                                        style={{ fontSize: 15 }}
                                    />
                                </Link>
                            </ListItemText>
                        </ListItem>

                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}
                            onClick={() => {
                                history.push('/settings/check-browser');
                            }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Check browser</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}
                            onClick={() => {
                                history.push('/settings/token');
                            }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Device Token</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            component={Paper}
                            style={{ marginBottom: '10px', height: '4rem' }}
                            onClick={() => {
                                history.push('/settings/test-push');
                            }}>
                            <ListItemText style={{ textAlign: 'left' }}>
                                <strong>Test push notifications</strong>
                            </ListItemText>
                            <ListItemText
                                style={{ textAlign: 'right' }}
                                className={classes.primaryColor}>
                                <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="center">
                <Button className={classes.btnPrimary} onClick={HandleLogout}>
                    Logout
                </Button>
            </Grid>
        </React.Fragment>
    );
}
