import React from 'react';
import PageTitle from './PageTitle';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Link } from '@material-ui/core';
import icon from '../assets/images/Type-1.svg';
const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#424242',
        margin: '10px 0',
        border: '1px solid gray',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
    },
    headerRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '0 5px',
        '& p': {
            color: 'gray',
            margin: 0,
        },
    },
    connectionIcon: {
        width: '50px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '5px 0',
        '& p': {
            margin: 0,
        },
    },
    column: {
        width: '20%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    narrowColumn: {
        width: '10%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    mainColumn: {
        width: '40%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    addressLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
    },
    address: {
        fontWeight: 600,
        margin: '0 5px',
    },
}));
export const LocationsListView = ({ locations, setIsMapView, isMapView }) => {
    const classes = useStyles();
    return (
        <div style={{ width: '100%' }}>
            <PageTitle
                title={'EV Charging'}
                backButtonCallback={() => setIsMapView(!isMapView)}
            />
            {locations.map(location => {
                return (
                    <div className={classes.container} key={location.id}>
                        <div className={classes.addressLine}>
                            <LocationOnIcon fill="gray" />
                            <Link
                                className={classes.address}
                                target={'_blank'}
                                href={`https://www.google.com/maps/search/?api=1&query=${location.originalObject.AddressInfo.Latitude},${location.originalObject.AddressInfo.Longitude}`}>
                                {location.originalObject.AddressInfo.Town}
                                {' ' +
                                    location.originalObject.AddressInfo
                                        .AddressLine1}
                            </Link>
                        </div>
                        <div className={classes.headerRow}>
                            <div className={classes.mainColumn}>
                                <p>ID: {location.id}</p>
                            </div>
                            <div className={classes.column}>
                                <p>Power</p>
                            </div>
                            <div className={classes.column}>
                                <p>V</p>
                            </div>
                            <div className={classes.narrowColumn}>
                                <p>Qty</p>
                            </div>
                            <div className={classes.narrowColumn}>
                                <p>Amps</p>
                            </div>
                        </div>
                        {location.originalObject.Connections.map(
                            (connection, i) => {
                                return (
                                    <div key={i} className={classes.row}>
                                        <div className={classes.mainColumn}>
                                            <img
                                                className={
                                                    classes.connectionIcon
                                                }
                                                alt="icon"
                                                src={icon}
                                            />
                                            <p>
                                                {' '}
                                                {connection.ConnectionType.Title.replace(
                                                    /\(.+\)/g,
                                                    '',
                                                )}
                                            </p>
                                        </div>
                                        <div className={classes.column}>
                                            <p>{connection.PowerKW}kW</p>
                                        </div>
                                        <div className={classes.column}>
                                            <p>{connection.Voltage}V</p>
                                        </div>
                                        <div className={classes.narrowColumn}>
                                            <p>{connection.Quantity}</p>
                                        </div>
                                        <div className={classes.narrowColumn}>
                                            <p>{connection.Amps}</p>
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    </div>
                );
            })}
        </div>
    );
};
