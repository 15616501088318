import mapboxgl from 'mapbox-gl';
import { UserInfo } from '../interfaces';
mapboxgl.accessToken =
    'pk.eyJ1IjoiY2hhcmdlLWFjY291bnRzIiwiYSI6ImNrNWQzYWFjZTFyeHIzbHFoM3Y5cDlsdm0ifQ.1-jxpSnos65qghwoD1nSTw';

export const LOCATION_TYPE_KIOSK = 'kiosk';
export const LOCATION_TYPE_CHARGING_STATION = 'charging-station';
export const LOCATION_TYPE_EV_CHARGING_STATION = 'ev-charging-station';
export const LOCATION_TYPE_PARKING = 'parking';

interface IConfig {
    stage?: string;
    internalApiBaseUrl?: string;
    powerBanksApiBaseUrl?: string;
    powerBanksS3BaseUrl?: string;
    stripePk?: string;
    resendOTPTimeout: number | string;
    bugsnagApiKey?: string;
    openChargeMapApiKey?: string;
    openChargeMapApiBaseUrl?: string;
    phoneCountryCodes?: string[];
    maxChargeRatePerDay: number | string;
    locationTypesDefaultColor: { color: string };
    locationTypesColors: Record<string, { color: string }>;
    user: {
        userInfo: UserInfo | null;
        cardsCount: number;
        ordersCount: number;
        messagesCount: number;
        isBusinessProfile: () => boolean;
        getCardsCount: () => number;
        getOrdersCount: () => number;
        setOrdersCount: (count: number) => void;
        getMessagesCount: () => number;
        setMessagesCount: (count: number) => void;
        uid?: string;
    };
    getUserInfo: () => UserInfo;
    qrCodeTypeMapping: Record<string, string>;
}

const config: IConfig = {
    stage: process.env.REACT_APP_STAGE,
    internalApiBaseUrl: process.env.REACT_APP_INTERNAL_API_BASE_URL,
    powerBanksApiBaseUrl: process.env.REACT_APP_POWER_BANKS_API_BASE_URL,
    powerBanksS3BaseUrl: process.env.REACT_APP_POWER_BANKS_S3_BASE_URL,
    stripePk: process.env.REACT_APP_STRIPE_PK,
    resendOTPTimeout: process.env.REACT_APP_OTP_TIMEOUT
        ? process.env.REACT_APP_OTP_TIMEOUT
        : 59,
    bugsnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    openChargeMapApiKey: process.env.REACT_APP_OPENCHARGE_MAP_API_KEY,
    openChargeMapApiBaseUrl: process.env.REACT_APP_OPENCHARGE_MAP_BASE_URL,
    phoneCountryCodes: ['us'],
    qrCodeTypeMapping: {
        CHST: LOCATION_TYPE_CHARGING_STATION,
        CHHB: LOCATION_TYPE_CHARGING_STATION,
        CHEV: LOCATION_TYPE_EV_CHARGING_STATION,
        CHAR: LOCATION_TYPE_KIOSK,
        RUSH: LOCATION_TYPE_KIOSK,
        RL3H: LOCATION_TYPE_KIOSK,
        CHPK: LOCATION_TYPE_PARKING,
    },
    locationTypesDefaultColor: { color: 'rgb(24, 144, 255)' },
    locationTypesColors: {
        kiosk: { color: 'rgb(24, 144, 255)' },
        'charging-station': { color: 'rgb(64,51,167)' },
        'ev-charging-station': { color: 'rgb(2,131,54)' },
        parking: { color: 'rgb(86,41,102)' },
    },
    maxChargeRatePerDay: process.env.REACT_APP_MAX_CHARGE_RATE_PER_DAY ?? 8,
    user: {
        userInfo: null,
        cardsCount: 0,
        ordersCount: 0,
        messagesCount: 0,
        isBusinessProfile() {
            return !!this.userInfo?.assigned_merchant;
        },
        getCardsCount() {
            return this.userInfo?.cards?.length ?? 0;
        },
        getOrdersCount() {
            return this.ordersCount;
        },
        setOrdersCount(count) {
            this.ordersCount = count;
        },
        getMessagesCount() {
            return this.messagesCount;
        },
        setMessagesCount(count) {
            this.messagesCount = count;
        },
    },
    getUserInfo() {
        let localStorageUserInfo = JSON.parse(
            localStorage.getItem('userInfo') ?? '',
        );
        config.user.userInfo = localStorageUserInfo;

        return localStorageUserInfo;
    },
};

export default config;
