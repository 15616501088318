import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import PageTitle from '../../../PageTitle';
import { Grid, Link, makeStyles, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import TabPanel from '../../../generic/TabPanel';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
    btnSecondary: {
        marginTop: '1rem',
        color: theme.palette.primary.dark,
        width: 'calc(100% - 15px)',
    },
    fullWidth: theme.fullWidth,
    buttonGroup: {
        marginBottom: '0.5rem',
    },
    buttonGroupItem: {
        maxWidth: '40px',
    },
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    },
})(MuiTableCell);

function ParkingInfoPagePartial(props) {
    const { location } = props;
    let history = useHistory();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [locationInfo, setLocationInfo] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const { currentUser } = useAuth();
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);

    useEffect(() => {
        setError(null);
        if (!currentUser) {
            history.push('/login');
        }

        if (!location) {
            history.push('/');
        }

        if (location !== null) {
            setLocationInfo(location);
        }
    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push('/');
    };

    const setTabProperties = (index, disabled = false) => {
        return {
            id: `scrollable-prevent-tab-${index}`,
            'aria-controls': `scrollable-prevent-tabpanel-${index}`,
            disabled: disabled,
        };
    };

    const HandleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const HandleReserve = () => {
        setSuccessMessage('Reservation success');
    };

    const HandleSelectSlot = id => {
        if (selectedSlot !== id) {
            setSelectedSlot(id);
        } else {
            setSelectedSlot(null);
        }
    };

    return (
        <React.Fragment>
            {loading && (
                <LinearProgress className={classes.fullWidth} color="primary" />
            )}
            {error && (
                <Alert
                    variant="outlined"
                    className={classes.fullWidth}
                    severity="error"
                    onClose={() => {
                        setError(null);
                    }}>
                    {error}
                </Alert>
            )}
            <PageTitle
                title={'Location Info'}
                backButtonCallback={HandleBack}
            />
            {successMessage && (
                <React.Fragment>
                    <Alert variant="outlined" severity="success">
                        {successMessage}
                    </Alert>
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <Button
                            style={{ width: '100%' }}
                            variant="contained"
                            onClick={() => history.push('/')}
                            color={'primary'}>
                            Ok
                        </Button>
                    </Grid>
                </React.Fragment>
            )}
            {!loading && locationInfo && !successMessage && (
                <Grid
                    container
                    direction="row"
                    wrap={'wrap'}
                    alignItems="center"
                    justify="flex-start">
                    <Grid item style={{ width: '100%', textAlign: 'center' }}>
                        <TableContainer>
                            <Table size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow key={'locationStationType'}>
                                        <TableCell align="right">
                                            <strong>Type</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            Parking
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'locationStationId'}>
                                        <TableCell align="right">
                                            <strong>ID</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            {locationInfo.id || ''}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'locationAddress'}>
                                        <TableCell align="right">
                                            <strong>Address</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Link
                                                target={'_blank'}
                                                href={`https://www.google.com/maps/search/?api=1&query=${locationInfo.coordinates.lat},${locationInfo.coordinates.lng}`}>
                                                {locationInfo.address}
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'locationLotsAvailable'}>
                                        <TableCell align="right">
                                            <strong>Available</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            {locationInfo.available || 0}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'locationLotsTotal'}>
                                        <TableCell align="right">
                                            <strong>Total</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            {locationInfo.total || 0}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item style={{ width: '100%', textAlign: 'center' }}>
                        <h4>Parking locations</h4>
                        <Tabs
                            value={activeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={HandleTabChange}
                            scrollButtons="auto"
                            variant="scrollable"
                            aria-label="Location info">
                            <Tab label="Floor 1" {...setTabProperties(0)} />
                            <Tab label="Floor 2" {...setTabProperties(1)} />
                            <Tab label="Floor 3" {...setTabProperties(2)} />
                            <Tab label="Floor 4" {...setTabProperties(3)} />
                        </Tabs>
                        <TabPanel value={activeTab} index={0}>
                            {selectedSlot === null && (
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        paddingTop: '0.5rem',
                                    }}>
                                    {Array(7)
                                        .fill(1)
                                        .map((rowItem, rowIndex) => (
                                            <ButtonGroup
                                                key={'floor1-' + rowIndex}
                                                className={classes.buttonGroup}
                                                disableElevation
                                                variant={'contained'}
                                                color={'primary'}>
                                                {Array(6)
                                                    .fill(1)
                                                    .map((item, index) => (
                                                        <Button
                                                            className={
                                                                classes.buttonGroupItem
                                                            }
                                                            onClick={() => {
                                                                HandleSelectSlot(
                                                                    100 +
                                                                        rowIndex *
                                                                            6 +
                                                                        index,
                                                                );
                                                            }}
                                                            key={
                                                                'parking' +
                                                                (100 +
                                                                    rowIndex *
                                                                        6 +
                                                                    index)
                                                            }>
                                                            {100 +
                                                                rowIndex * 6 +
                                                                index}
                                                        </Button>
                                                    ))}
                                            </ButtonGroup>
                                        ))}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                            {selectedSlot === null && (
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        paddingTop: '0.5rem',
                                    }}>
                                    {Array(7)
                                        .fill(1)
                                        .map((rowItem, rowIndex) => (
                                            <ButtonGroup
                                                key={'floor2-' + rowIndex}
                                                className={classes.buttonGroup}
                                                disableElevation
                                                variant={'contained'}
                                                color={'primary'}>
                                                {Array(6)
                                                    .fill(1)
                                                    .map((item, index) => (
                                                        <Button
                                                            className={
                                                                classes.buttonGroupItem
                                                            }
                                                            onClick={() => {
                                                                HandleSelectSlot(
                                                                    200 +
                                                                        rowIndex *
                                                                            6 +
                                                                        index,
                                                                );
                                                            }}
                                                            key={
                                                                'parking' +
                                                                (200 +
                                                                    rowIndex *
                                                                        6 +
                                                                    index)
                                                            }>
                                                            {200 +
                                                                rowIndex * 6 +
                                                                index}
                                                        </Button>
                                                    ))}
                                            </ButtonGroup>
                                        ))}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value={activeTab} index={2}>
                            {selectedSlot === null && (
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        paddingTop: '0.5rem',
                                    }}>
                                    {Array(7)
                                        .fill(1)
                                        .map((rowItem, rowIndex) => (
                                            <ButtonGroup
                                                key={'floor3-' + rowIndex}
                                                className={classes.buttonGroup}
                                                disableElevation
                                                variant={'contained'}
                                                color={'primary'}>
                                                {Array(6)
                                                    .fill(1)
                                                    .map((item, index) => (
                                                        <Button
                                                            className={
                                                                classes.buttonGroupItem
                                                            }
                                                            onClick={() => {
                                                                HandleSelectSlot(
                                                                    300 +
                                                                        rowIndex *
                                                                            6 +
                                                                        index,
                                                                );
                                                            }}
                                                            key={
                                                                'parking' +
                                                                (300 +
                                                                    rowIndex *
                                                                        6 +
                                                                    index)
                                                            }>
                                                            {300 +
                                                                rowIndex * 6 +
                                                                index}
                                                        </Button>
                                                    ))}
                                            </ButtonGroup>
                                        ))}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value={activeTab} index={3}>
                            {selectedSlot === null && (
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        paddingTop: '0.5rem',
                                    }}>
                                    {Array(7)
                                        .fill(1)
                                        .map((rowItem, rowIndex) => (
                                            <ButtonGroup
                                                key={'floor4-' + rowIndex}
                                                className={classes.buttonGroup}
                                                disableElevation
                                                variant={'contained'}
                                                color={'primary'}>
                                                {Array(6)
                                                    .fill(1)
                                                    .map((item, index) => (
                                                        <Button
                                                            className={
                                                                classes.buttonGroupItem
                                                            }
                                                            onClick={() => {
                                                                HandleSelectSlot(
                                                                    400 +
                                                                        rowIndex *
                                                                            6 +
                                                                        index,
                                                                );
                                                            }}
                                                            key={
                                                                'parking' +
                                                                (400 +
                                                                    rowIndex *
                                                                        6 +
                                                                    index)
                                                            }>
                                                            {400 +
                                                                rowIndex * 6 +
                                                                index}
                                                        </Button>
                                                    ))}
                                            </ButtonGroup>
                                        ))}
                                </Grid>
                            )}
                        </TabPanel>
                    </Grid>

                    {selectedSlot !== null && (
                        <Grid
                            item
                            style={{ width: '100%', textAlign: 'center' }}>
                            <h4
                                style={{
                                    marginTop: '0.2rem',
                                    marginBottom: '0.2rem',
                                }}>
                                Selected: {selectedSlot}
                            </h4>
                            <Button
                                className={classes.fullWidth}
                                disabled={loading}
                                onClick={HandleReserve}
                                variant="contained"
                                color={'primary'}>
                                Reserve
                            </Button>
                            <Button
                                className={classes.btnSecondary}
                                disabled={loading}
                                onClick={() => {
                                    HandleSelectSlot(selectedSlot);
                                }}
                                variant="contained">
                                Cancel
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
}

ParkingInfoPagePartial.propTypes = {
    location: PropTypes.any.isRequired,
};

export default ParkingInfoPagePartial;
