import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PageTitle from '../PageTitle';
import { Capacitor } from '@capacitor/core';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    },
})(MuiTableCell);

export default function CheckDeviceToken() {
    let history = useHistory();
    const classes = useStyles();
    const { currentUser, token } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            history.push('/login');
        }
    }, [history, currentUser]);

    const HandleBack = () => {
        history.push('/settings');
    };

    return (
        <React.Fragment>
            <PageTitle title={'Check token'} backButtonCallback={HandleBack} />
            <Grid
                container
                direction="row"
                wrap={'wrap'}
                alignItems="center"
                justify="flex-start">
                <Grid item style={{ width: '100%' }}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table">
                            <TableBody>
                                <TableRow key={'checkBrowser'}>
                                    <TableCell align="left">
                                        <strong>Detected device</strong>
                                    </TableCell>
                                    <TableCell align="right" colSpan={3}>
                                        {Capacitor.getPlatform() === 'android'
                                            ? localStorage.getItem(
                                                  'deviceToken',
                                              )
                                            : token}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
