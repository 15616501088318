export enum RoutesEnum {
    Home = '/',
    Cards = '/cards',
    AddCard = '/cards/add',
    CardInfo = '/cards/:cardId',
    Orders = '/orders',
    OrderInfo = '/order/info',
    Messages = '/messages',
    MessagesInfo = '/message/info',
    Help = '/help',
    Settings = '/settings',
    PrivacyPolicy = '/settings/privacy-policy',
    TermsAndConditions = '/settings/terms-and-condition',
    Account = '/settings/account',
    AccountConnectToBuisness = '/settings/account/connect-to-business',
    CheckBrowser = '/settings/check-browser',
    Subscriptions = '/settings/subscriptions',
    TestPushNotifications = '/settings/test-push',
    DeviceToken = '/settings/token',
    Login = '/login',
    LoginBuisness = '/login/business/:accessCodeParam?',
    SignUp = '/signup/:referralCodeParam?',
    SignupBuisness = '/signup/business/:accessCodeParam?',
    LogOut = '/logout',
    Scan = '/scan',
    Confirm = '/confirm/:locationId/:referralCodeParam?',
    LeaseResult = '/lease/result',
    LocationInfo = '/location/info',
}
