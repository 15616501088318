import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import PageTitle from '../../../PageTitle';
import { Grid, Link, makeStyles, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import MuiTableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main,
    },
    btnSecondary: {
        marginTop: '1rem',
        color: theme.palette.primary.dark,
        width: 'calc(100% - 15px)',
    },
    fullWidth: theme.fullWidth,
    buttonGroup: {
        marginBottom: '0.5rem',
    },
    buttonGroupItem: {
        maxWidth: '40px',
    },
    buttonGroupItemSelected: {
        maxWidth: '40px',
        color: 'aqua',
    },
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    },
})(MuiTableCell);

function ChargingStationInfoPagePartial(props) {
    const { location } = props;
    let history = useHistory();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [locationInfo, setLocationInfo] = useState(null);
    const classes = useStyles();
    const { currentUser } = useAuth();
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    // eslint-disable-next-line
    const [locationTypePrefix, setLocationTypePrefix] = useState(null);

    useEffect(() => {
        setError(null);
        if (!currentUser) {
            history.push('/login');
        }

        if (!location) {
            history.push('/');
        }

        if (location !== null) {
            setLocationInfo(location);
            setLocationTypePrefix(
                location.type === 'station' ? 'CHST' : 'CHHB',
            );
        }
    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push('/');
    };
    const HandleReserve = () => {
        setSuccessMessage('Reservation success');
        // history.push({
        //     pathname: `/confirm/${locationTypePrefix}${location.id}`,
        //     state: {
        //         from: '/location/info',
        //         location: location,
        //         selectedSlot: selectedSlot
        //     }
        // })
    };

    const HandleSelectSlot = id => {
        if (selectedSlot !== id) {
            setSelectedSlot(id);
        } else {
            setSelectedSlot(null);
        }
    };

    return (
        <React.Fragment>
            {loading && (
                <LinearProgress className={classes.fullWidth} color="primary" />
            )}
            {error && (
                <Alert
                    variant="outlined"
                    className={classes.fullWidth}
                    severity="error"
                    onClose={() => {
                        setError(null);
                    }}>
                    {error}
                </Alert>
            )}
            <PageTitle
                title={'Location Info'}
                backButtonCallback={HandleBack}
            />
            {successMessage && (
                <React.Fragment>
                    <Alert variant="outlined" severity="success">
                        {successMessage}
                    </Alert>
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <Button
                            style={{ width: '100%' }}
                            variant="contained"
                            onClick={() => history.push('/')}
                            color={'primary'}>
                            Ok
                        </Button>
                    </Grid>
                </React.Fragment>
            )}
            {!loading && locationInfo && !successMessage && (
                <Grid
                    container
                    direction="row"
                    wrap={'wrap'}
                    alignItems="center"
                    justify="flex-start">
                    <Grid item style={{ width: '100%', textAlign: 'center' }}>
                        <TableContainer>
                            <Table size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow key={'locationStationType'}>
                                        <TableCell align="right">
                                            <strong>Type</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            {'Charging '}
                                            {locationInfo.type || ''}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'locationStationId'}>
                                        <TableCell align="right">
                                            <strong>ID</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            {locationInfo.id || ''}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={'locationAddress'}>
                                        <TableCell align="right">
                                            <strong>Address</strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Link
                                                target={'_blank'}
                                                href={`https://www.google.com/maps/search/?api=1&query=${locationInfo.coordinates.lat},${locationInfo.coordinates.lng}`}>
                                                {locationInfo.address}
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                    {locationInfo.type === 'station' && (
                                        <React.Fragment>
                                            <TableRow
                                                key={'locationStallsAvailable'}>
                                                <TableCell align="right">
                                                    <strong>Available</strong>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {locationInfo.stallsAvailable ||
                                                        0}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={'locationStallsTotal'}>
                                                <TableCell align="right">
                                                    <strong>Total</strong>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {locationInfo.stallsTotal ||
                                                        0}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {selectedSlot === null &&
                            locationInfo.type === 'station' && (
                                <React.Fragment>
                                    <Grid
                                        item
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}>
                                        <h3>Station stalls</h3>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}>
                                        <ButtonGroup
                                            className={classes.buttonGroup}
                                            disableElevation
                                            variant="contained"
                                            color="primary">
                                            <Button
                                                className={
                                                    classes.buttonGroupItem
                                                }
                                                onClick={() => {
                                                    HandleSelectSlot(0);
                                                }}
                                                disabled={
                                                    !locationInfo.availableStallsList.includes(
                                                        0,
                                                    )
                                                }>
                                                01
                                            </Button>
                                            <Button
                                                className={
                                                    classes.buttonGroupItem
                                                }
                                                onClick={() => {
                                                    HandleSelectSlot(1);
                                                }}
                                                disabled={
                                                    !locationInfo.availableStallsList.includes(
                                                        1,
                                                    )
                                                }>
                                                02
                                            </Button>
                                            <Button
                                                className={
                                                    classes.buttonGroupItem
                                                }
                                                onClick={() => {
                                                    HandleSelectSlot(2);
                                                }}
                                                disabled={
                                                    !locationInfo.availableStallsList.includes(
                                                        2,
                                                    )
                                                }>
                                                03
                                            </Button>
                                            <Button
                                                className={
                                                    classes.buttonGroupItem
                                                }
                                                onClick={() => {
                                                    HandleSelectSlot(3);
                                                }}
                                                disabled={
                                                    !locationInfo.availableStallsList.includes(
                                                        3,
                                                    )
                                                }>
                                                04
                                            </Button>
                                            <Button
                                                className={
                                                    classes.buttonGroupItem
                                                }
                                                onClick={() => {
                                                    HandleSelectSlot(4);
                                                }}
                                                disabled={
                                                    !locationInfo.availableStallsList.includes(
                                                        4,
                                                    )
                                                }>
                                                05
                                            </Button>
                                            <Button
                                                className={
                                                    classes.buttonGroupItem
                                                }
                                                onClick={() => {
                                                    HandleSelectSlot(5);
                                                }}
                                                disabled={
                                                    !locationInfo.availableStallsList.includes(
                                                        5,
                                                    )
                                                }>
                                                06
                                            </Button>
                                        </ButtonGroup>
                                        {locationInfo.stallsTotal > 6 && (
                                            <ButtonGroup
                                                className={classes.buttonGroup}
                                                disableElevation
                                                variant="contained"
                                                color="primary">
                                                <Button
                                                    className={
                                                        classes.buttonGroupItem
                                                    }
                                                    onClick={() => {
                                                        HandleSelectSlot(6);
                                                    }}
                                                    disabled={
                                                        !locationInfo.availableStallsList.includes(
                                                            6,
                                                        )
                                                    }>
                                                    07
                                                </Button>
                                                <Button
                                                    className={
                                                        classes.buttonGroupItem
                                                    }
                                                    onClick={() => {
                                                        HandleSelectSlot(7);
                                                    }}
                                                    disabled={
                                                        !locationInfo.availableStallsList.includes(
                                                            7,
                                                        )
                                                    }>
                                                    08
                                                </Button>
                                                <Button
                                                    className={
                                                        classes.buttonGroupItem
                                                    }
                                                    onClick={() => {
                                                        HandleSelectSlot(8);
                                                    }}
                                                    disabled={
                                                        !locationInfo.availableStallsList.includes(
                                                            8,
                                                        )
                                                    }>
                                                    09
                                                </Button>
                                                <Button
                                                    className={
                                                        classes.buttonGroupItem
                                                    }
                                                    onClick={() => {
                                                        HandleSelectSlot(9);
                                                    }}
                                                    disabled={
                                                        !locationInfo.availableStallsList.includes(
                                                            9,
                                                        )
                                                    }>
                                                    10
                                                </Button>
                                                <Button
                                                    className={
                                                        classes.buttonGroupItem
                                                    }
                                                    onClick={() => {
                                                        HandleSelectSlot(10);
                                                    }}
                                                    disabled={
                                                        !locationInfo.availableStallsList.includes(
                                                            10,
                                                        )
                                                    }>
                                                    11
                                                </Button>
                                                <Button
                                                    className={
                                                        classes.buttonGroupItem
                                                    }
                                                    onClick={() => {
                                                        HandleSelectSlot(11);
                                                    }}
                                                    disabled={
                                                        !locationInfo.availableStallsList.includes(
                                                            11,
                                                        )
                                                    }>
                                                    12
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            )}
                        {selectedSlot === null &&
                            locationInfo.type === 'container' && (
                                <React.Fragment>
                                    <Grid
                                        item
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}>
                                        <h3>Station containers</h3>
                                    </Grid>
                                    {selectedSlot === null &&
                                        locationInfo.reservables?.length >
                                            0 && (
                                            <Grid
                                                item
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                }}>
                                                <ButtonGroup
                                                    className={
                                                        classes.buttonGroup
                                                    }
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary">
                                                    {locationInfo.reservables.map(
                                                        (item, index) => {
                                                            return (
                                                                <Button
                                                                    key={
                                                                        'bay' +
                                                                        index
                                                                    }
                                                                    onClick={() => {
                                                                        HandleSelectSlot(
                                                                            index,
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        item.currentAvailability ===
                                                                        'unavailable'
                                                                    }>
                                                                    {
                                                                        item.containerBayLabel
                                                                    }
                                                                </Button>
                                                            );
                                                        },
                                                    )}
                                                </ButtonGroup>
                                            </Grid>
                                        )}
                                </React.Fragment>
                            )}
                        {selectedSlot !== null && (
                            <Grid
                                item
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    paddingTop: '1rem',
                                }}>
                                <h4>
                                    Selected:{' '}
                                    {locationInfo.type === 'container'
                                        ? 'Bay'
                                        : 'Stall'}{' '}
                                    {selectedSlot + 1}
                                </h4>
                                <Button
                                    className={classes.fullWidth}
                                    disabled={loading}
                                    onClick={HandleReserve}
                                    variant="contained"
                                    color={'primary'}>
                                    Reserve
                                </Button>
                                <Button
                                    className={classes.btnSecondary}
                                    disabled={loading}
                                    onClick={() => {
                                        HandleSelectSlot(selectedSlot);
                                    }}
                                    variant="contained">
                                    Cancel
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}

ChargingStationInfoPagePartial.propTypes = {
    location: PropTypes.any.isRequired,
};

export default ChargingStationInfoPagePartial;
