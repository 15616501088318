import React, { useContext, useState } from 'react';

const MapContext = React.createContext({});

export function useMap() {
    return useContext(MapContext);
}

export function MapProvider({ children }: { children: React.ReactNode }) {
    const [mapCoordinates, setMapCoordinates] = useState({
        locationLat: 26.94184881952455,
        locationLng: -80.13336003048181,
        zoom: 12,
    });
    const value = {
        setMapCoordinates,
        mapCoordinates,
    };

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
